import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import {
  DialInDetails,
  LAYOUT_ID_MAPPING,
  Layouts,
  LayoutsState,
  LocalMediaState,
  MediaOption,
  MeetingLockType,
  MeetingsState,
  MuteState,
  MuteType,
  OngoingMeetingState,
  Participant,
  ParticipantState,
  RemoteMediaState,
} from '@/custom_typings/cafexmeetings/meetings-api';
import BrowserDetection from '@/data/BrowserDetection';
import { BackedAutocompleteItem } from '@/data/datatypes/components/AutocompleteItem';
import { PastMeetingDetails } from '@/data/datatypes/meeting/PastMeetingDetails';
import ScheduledMeeting from '@/data/datatypes/meeting/ScheduledMeeting';
import { ThirdPartyMeetingDetails } from '@/data/datatypes/meeting/ThirdPartyMeetingDetails';
import { WaitingRoomData, WaitingRoomUser, WaitingRoomUserDetails } from '@/data/datatypes/meeting/WaitingRoomData';
import { MeetingStatus } from '@/data/datatypes/online/OnlineStatus';
import { PresentationDetail } from '@/data/datatypes/online/PresentationDetail';
import { TrackOnlineStatus } from '@/data/datatypes/online/TrackOnlineStatus';
import { ExternalTenantUsersByPermission } from '@/data/datatypes/permissions/ExternalTenantUsersByPermission';
import { CallMessageType, Track, TrackType } from '@/data/datatypes/Track';
import { EntryType, TrackEntry } from '@/data/datatypes/TrackEntry';
import { FullUserDetails, LimitedUserDetails, TenantUserDetails } from '@/data/datatypes/UserDetails';
import UserToken from '@/data/datatypes/UserToken';
import MeetingsHelper from '@/data/helpers/MeetingsHelper';
import DataWorker from '@/data/storage/DataWorker';
import { useChatMessagesStore } from '@/stores/ChatMessages';
import { useEntryTextFilesStore } from '@/stores/EntryTextFiles';
import pinia from '@/stores/index';
import {
  InitMeetingsPayload,
  InvitePayload,
  MeetingGuestLoggedInDetails,
  MeetingLocation,
  NodeDetail,
  PastMeetingPayload,
  RecordingPayload,
  StreamListenerImpl,
} from '@/stores/Meeting.types';
import { asRecord, patchObject } from '@/stores/StoreHelper';
import { useTrackEntriesStore } from '@/stores/TrackEntries';
import { ScheduledMeetingPayload } from '@/stores/TrackEntries.types';
import { useTrackMembersStore } from '@/stores/TrackMembers';
import { useTrackOnlineStatusesStore } from '@/stores/TrackOnlineStatuses';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';
import { GetUserDetailsAsGuestPayload } from '@/stores/User.types';

export const useMeetingStore = defineStore('Meeting', () => {
  const trackOnlineStatusesStore = useTrackOnlineStatusesStore(pinia);
  const trackMembersStore = useTrackMembersStore(pinia);
  const tracksStore = useTracksStore(pinia);
  const userStore = useUserStore(pinia);

  const meetingApiInitialised: Ref<boolean> = ref(false);
  const inMeeting: Ref<OngoingMeetingState> = ref(OngoingMeetingState.NO_MEETING);
  const meetingState: Ref<MeetingsState | undefined> = ref(undefined);
  const nodeDetails: Ref<Record<string, NodeDetail>> = ref({});
  const participantState: Ref<ParticipantState | undefined> = ref(undefined);
  const requestedVideoLayout: Ref<string> = ref(Layouts.ACTIVE_SPEAKER as string);
  const userRequestedVideoLayout: Ref<string> = ref(Layouts.ACTIVE_SPEAKER as string);
  const usersInWaitingRoom: Ref<WaitingRoomUserDetails[]> = ref([]);
  const showSettings: Ref<boolean> = ref(false);
  const isSecondaryMenuExpanded: Ref<boolean> = ref(false);
  const micVolume: Ref<number> = ref(2);
  const joinAudioMuted: Ref<boolean> = ref(false);
  const joinVideoMuted: Ref<boolean> = ref(false);
  const audioForceMuted: Ref<boolean> = ref(false);
  const videoForceMuted: Ref<boolean> = ref(false);
  const audioRequestUnmute: Ref<boolean> = ref(false);
  const videoRequestUnmute: Ref<boolean> = ref(false);
  const meetingLocation: Ref<MeetingLocation | undefined> = ref(undefined);
  const activeVideoLocation: Ref<MeetingLocation> = ref({ top: 0, left: 0, height: 0, width: 0 });
  const selfVideoLocation: Ref<MeetingLocation> = ref({ top: 0, left: 0, height: 0, width: 0 });
  const selfViewTimer: Ref<number> = ref(-1);
  const activeViewTimer: Ref<number> = ref(-1);
  const meetingTrackId: Ref<string | null> = ref(null);
  const thirdPartyMeetingTrackId: Ref<string | null> = ref(null);
  const multitaskMeetingView: Ref<boolean> = ref(false);
  const defaultGuestMeetingEntries: Ref<Record<string, TrackEntry>> = ref({});
  const thirdPartyMeetingDetailsByTrack: Ref<Record<string, ThirdPartyMeetingDetails>> = ref({});

  /**
   * When the user (typically a coordinator) changes the conference access mode we keep track
   * of it via this state. This helps avoid inconsistencies/flipping states whilst the refresh
   * and update races each others.
   *
   * => You are probably looking for the "lockState" getter instead...
   */
  const requestedLockState: Ref<MeetingLockType | null> = ref(null);

  /**
   * The conference access mode that was returned to us in the most recent pool request.
   * Note: This state possibly won't represent the actual state as an update could be in progress.
   *
   * => You are probably looking for the "lockState" getter instead...
   */
  const lastKnownLockState: Ref<MeetingLockType> = ref(MeetingLockType.UNLOCKED);

  /**
   * Track if the request to change the lock state (i.e. access mode) has been executed by the server.
   *
   * True => Means the lock state change has been executed (whatever it was a success or failure).
   *         And that we need to reset the requestedLockState.
   * False => Means no changes have been requested or we are still waiting for the change to be executed,
   *          meaning requestedLockState will be defining the lock state.
   */
  const lockStateUpdateExecuted: Ref<boolean> = ref(false);

  const waitingRoomPoll: Ref<ReturnType<typeof setTimeout> | null> = ref(null);

  const videoLocation: ComputedRef<MeetingLocation> = computed(() => {
    if (selfVideoLocation.value.height !== 0 && selfVideoLocation.value.width !== 0) {
      return selfVideoLocation.value;
    }
    return activeVideoLocation.value;
  });

  const videoMuteState: ComputedRef<MuteState> = computed(() => {
    if (!selfParticipant.value) {
      return preMeetVideoMuteState.value;
    }

    if (videoForceMuted.value) {
      return MuteState.forceMuted;
    } else if (selfParticipant.value?.videoMuted) {
      return MuteState.muted;
    } else {
      return MuteState.enabled;
    }
  });

  const audioMuteState: ComputedRef<MuteState> = computed(() => {
    if (!selfParticipant.value) {
      return preMeetAudioMuteState.value;
    }

    if (audioForceMuted.value) {
      return MuteState.forceMuted;
    } else if (selfParticipant.value?.audioMuted) {
      return MuteState.muted;
    } else {
      return MuteState.enabled;
    }
  });

  const preMeetAudioMuteState: ComputedRef<MuteState> = computed(() => {
    if (localMediaState.value === LocalMediaState.LOCAL_AUDIO_FAILED ||
      localMediaState.value === LocalMediaState.LOCAL_MEDIA_FAILED) {
      return MuteState.muted;
    }
    return joinAudioMuted.value ? MuteState.muted : MuteState.enabled;
  });

  const preMeetVideoMuteState: ComputedRef<MuteState> = computed(() => {
    if (localMediaState.value === LocalMediaState.LOCAL_VIDEO_FAILED ||
      localMediaState.value === LocalMediaState.LOCAL_MEDIA_FAILED) {
      return MuteState.muted;
    }
    return joinVideoMuted.value ? MuteState.muted : MuteState.enabled;
  });

  const audioDevices: ComputedRef<MediaOption[]> = computed(() => {
    if (meetingState.value !== undefined) {
      return meetingState.value.audioInputDevices;
    } else {
      return [];
    }
  });

  const videoDevices: ComputedRef<MediaOption[]> = computed(() => {
    if (meetingState.value !== undefined) {
      return meetingState.value.videoDevices;
    } else {
      return [];
    }
  });

  const speakers: ComputedRef<MediaOption[]> = computed(() => {
    if (meetingState.value !== undefined) {
      return meetingState.value.audioOutputDevices;
    } else {
      return [];
    }
  });

  const selfParticipant: ComputedRef<Participant | undefined> = computed(() => {
    return participantState.value?.participants.find((p) => p.nodeId === meetingState.value?.localNodeId);
  });

  const trueActiveSpeaker: ComputedRef<Participant | undefined> = computed(() => {
    return meetingState.value?.trueActiveSpeaker;
  });

  const mediaReceived: ComputedRef<boolean> = computed(() => {
    if (meetingState.value !== undefined) {
      return meetingState.value.mediaReceived;
    }
    return false;
  });

  const currentlyRecording: ComputedRef<boolean> = computed(() => {
    if (meetingState.value) {
      return meetingState.value.currentlyRecording;
    }
    return false;
  });

  const layouts: ComputedRef<LayoutsState | undefined> = computed(() => {
    return meetingState.value?.layouts;
  });

  const allParticipants: ComputedRef<Participant[]> = computed(() => {
    return participantState.value?.participants ?? [];
  });

  const localNodeId: ComputedRef<string | undefined> = computed(() => {
    return meetingState.value?.localNodeId;
  });

  const isScreenSharing: ComputedRef<boolean> = computed(() => {
    return !!meetingState.value?.sharingNodeId;
  });

  const passthroughNodeDetails: ComputedRef<Record<string, NodeDetail>> = computed(() => {
    return nodeDetails.value;
  });

  const selfSharing: ComputedRef<boolean> = computed(() => {
    return !!meetingState.value?.sharingNodeId &&
      meetingState.value?.sharingNodeId === meetingState.value?.localNodeId;
  });

  const remoteMediaState: ComputedRef<RemoteMediaState | undefined> = computed(() => {
    return meetingState.value?.mediaState.remoteMediaState;
  });

  const localMediaState: ComputedRef<LocalMediaState | undefined> = computed(() => {
    return meetingState.value?.mediaState.localMediaState;
  });

  const hasLocalMediaFailed: ComputedRef<boolean> = computed(() => {
    return localMediaState.value !== LocalMediaState.LOCAL_MEDIA_OK;
  });

  const hasRemoteMediaFailed: ComputedRef<boolean> = computed(() => {
    return !mediaReceived.value && remoteMediaState.value === RemoteMediaState.REMOTE_MEDIA_FAILED;
  });

  const currentVideoLayout: ComputedRef<string> = computed(() => {
    if (meetingState.value) {
      return LAYOUT_ID_MAPPING[meetingState.value.layoutIndex];
    }
    return '';
  });

  const localNetworkBad: ComputedRef<boolean> = computed(() => {
    if (selfParticipant.value !== undefined) {
      return selfParticipant.value.poorNetwork;
    }
    return false;
  });

  const sharingParticipant: ComputedRef<Participant | undefined> = computed(() => {
    return participantState.value?.participants.find((p) =>
      p.nodeId === meetingState.value?.sharingNodeId);
  });

  const calculatePossibleGuests: ComputedRef<(permissionType: string) => BackedAutocompleteItem<string>[]> =
    computed(() => {
      return (permissionType: string) => {
        const autocompleteItems: BackedAutocompleteItem<string>[] = [];
        const tenantUsers: TenantUserDetails[] = userStore.allTenantUsers
          .filter((user: TenantUserDetails) => user.status !== 'DEACTIVATED' && user.status !== 'PENDING_DEACTIVATED');
        const users: LimitedUserDetails[] = tenantUsers.concat(getExternalMeetingUsers(permissionType));
        for (const details of users) {
          MeetingsHelper.populateAutocompleteItems(autocompleteItems, details);
        }
        return autocompleteItems;
      };
    });

  const allTenantUsers: ComputedRef<(permissionType: string) => LimitedUserDetails[]> = computed(() => {
    return (permissionType: string) => {
      const tenantUsers: TenantUserDetails[] = Object.values(userStore.tenantUsers);
      return tenantUsers.concat(getExternalMeetingUsers(permissionType));
    };
  });

  const meetingEntry: ComputedRef<TrackEntry | null> = computed(() => {
    if (!meetingTrackId.value) {
      return null;
    }

    const onlineTrackStatus: TrackOnlineStatus = trackOnlineStatusesStore.statuses[meetingTrackId.value ?? ''];
    let meetingEntry: TrackEntry | undefined;

    if (onlineTrackStatus && onlineTrackStatus.liveMeetingRecordEntryId) {
      const trackEntriesStore = useTrackEntriesStore(pinia);
      const entries: Record<string, TrackEntry> = trackEntriesStore.entries[meetingTrackId.value ?? ''];
      meetingEntry = Object.values(entries).find((entry: TrackEntry) => {
        return entry.id === onlineTrackStatus.liveMeetingRecordEntryId && entry.type === EntryType.meeting_record;
      });
    }

    return meetingEntry || null;
  });

  const meetingRecordEntryForUserOrGuest: ComputedRef<TrackEntry | null> = computed(() => {
    const isGuest: boolean = userStore.isMeetingGuestOrGuestMember;
    if (isGuest) {
      const trackEntriesStore = useTrackEntriesStore(pinia);
      return trackEntriesStore.guestMeetingRecordEntry;
    } else {
      return meetingEntry.value;
    }
  });

  const getDialInNumbers: ComputedRef<DialInDetails[]> = computed(() => {
    return MeetingsApi.getInstance().getDialInNumbers();
  });

  const meetingTrack: ComputedRef<Track | null> = computed(() => {
    if (!meetingTrackId.value) {
      return null;
    }
    return tracksStore.tracks[meetingTrackId.value];
  });

  /**
   * Returns the current meeting room access mode (i.e. locking state).
   *
   * When the user changes the access mode, this getter will return what was specified by the user
   * (like that the user see what he would expect).
   * However, it will be reverted back to what the server tells us if the change was rejected.
   */
  const lockState: ComputedRef<MeetingLockType> = computed(() => {
    if (requestedLockState.value !== null) {
      return requestedLockState.value;
    }
    return lastKnownLockState.value;
  });

  /**
   * Returns true if the current user can wave participants into a meeting.
   * Currently, this is also used to determine if a user can change the access mode of a meeting
   * (as it uses the same checks).
   */
  const isMeetingCoordinator: ComputedRef<boolean> = computed(() => {
    const isMeetingGuestOrGuestMember: boolean = userStore.isMeetingGuestOrGuestMember;
    if (isMeetingGuestOrGuestMember) {
      return false;
    }
    const currentUserId: string | null = userStore.currentUserId;
    const owner = meetingTrack.value?.owner;
    if (owner && currentUserId && owner === currentUserId) {
      return true;
    }
    return trackMembersStore.isCoordinatorOfMeetingTrack;
  });

  const defaultMeetingEntry: ComputedRef<TrackEntry | null> = computed(() => {
    let trackId: string | null = meetingTrackId.value;
    if (!trackId) {
      trackId = tracksStore.activeTrackId;
    }
    if (!trackId) {
      return null;
    }

    const isGuest = userStore.isGuestUser || userStore.isGuestMember(trackId);
    if (isGuest) {
      return defaultGuestMeetingEntries.value[trackId];
    }

    const trackEntriesStore = useTrackEntriesStore(pinia);
    const trackEntries: Record<string, TrackEntry> = trackEntriesStore.entries[trackId];
    let meetingEntry: TrackEntry | null = null;
    for (const entryId in trackEntries) {
      const entry = trackEntries[entryId];
      if (entry.type === EntryType.meeting) {
        meetingEntry = entry;
        break;
      }
    }
    return meetingEntry;
  });

  function getExternalMeetingUsers(permissionType: string): LimitedUserDetails[] {
    const externalUsers: ExternalTenantUsersByPermission = userStore.allExternalTenantUsersByPermissionType;
    if (externalUsers) {
      const externalMeetingUsers = externalUsers[permissionType];
      if (externalMeetingUsers) {
        return externalMeetingUsers;
      }
    }
    return [];
  }

  function guestDisplayName(name: string, track?: Track): string {
    // Unexpectedly can't find a track, just return the name
    if (!track) {
      return name;
    }
    let guestNameSuffix = ' (Guest)';

    // Don't add the suffix if this is an adhoc, scheduled, or user track
    if ([TrackType.ADHOC_MEETING, TrackType.USER, TrackType.SCHEDULED_MEETING].includes(track?.type ?? '')) {
      guestNameSuffix = '';
    }

    return `${name}${guestNameSuffix}`;
  }

  async function populateWaitingUserDetails(newData: WaitingRoomUser[],
    onlineMeetingTrackMembers: LimitedUserDetails[]): Promise<WaitingRoomUserDetails[]> {
    const result: WaitingRoomUserDetails[] = [];
    for (const user of newData) {
      if (user.phoneNumber) {
        result.push({
          remoteId: user.remoteId,
          memberDetails: {
            id: 'PHONE_ID_' + user.phoneNumber,
            email: '',
            displayName: user.phoneNumber,
          }
        });
        continue;
      }
      let matched = false;
      const userDetails = onlineMeetingTrackMembers?.find(element => element.id === user.userId);
      if (userDetails) {
        result.push({
          remoteId: user.remoteId,
          memberDetails: userDetails,
        });
        matched = true;
      }
      if (!matched && user.userId) {
        const userDetails = await userStore.fetchUserDetails(user.userId);
        if (userDetails) {
          result.push({
            remoteId: user.remoteId,
            memberDetails: userDetails,
          });
        }
      }
    }

    return result;
  }

  async function initMeeting(payload: InitMeetingsPayload): Promise<{ screenshareSupported: boolean }> {
    const chatMessagesStore = useChatMessagesStore(pinia);
    // TODO: We could limit which devices run in limited mode here, this must be called before init.
    // meetingApi.shouldIgnoreLimitedMode(true);
    if (BrowserDetection.isIosApp()) {
      MeetingsApi.getInstance().shouldIgnoreLimitedMode(true);
    }
    MeetingsApi.getInstance().setClientSideVideoOverlay(true);

    MeetingsApi.getInstance().registerMeetingsCallback((state) => {
      if (state?.mediaReceived && !meetingState.value?.mediaReceived) {
        // TODO: remember user preference
        const startActiveSpeaker = BrowserDetection.isMobile() || !!state.sharingNodeId;
        const initialLayout: string = startActiveSpeaker ? Layouts.ACTIVE_SPEAKER : Layouts.GRID;
        updateVideoLayout({ requestedVideoLayout: initialLayout, userRequested: true });
      }
      setMeetingsState(state);
    });
    MeetingsApi.getInstance().registerParticipantCallback((participantState) => {
      setParticipantState(participantState);
    });

    if (payload.audioOnly) {
      MeetingsConfig.FORCE_MUTE_VIDEO_AT_START = true;
      setJoinVideoMuted(true);
    } else {
      MeetingsConfig.FORCE_MUTE_VIDEO_AT_START = false;
    }

    await MeetingsApi.getInstance().initMeeting(payload.meetingUrl, payload.meetingId, payload.externalUser);

    await MeetingsApi.getInstance().registerForceMuteCallback((muteType: MuteType) => {
      switch (muteType) {
        case MuteType.AUDIO:
          if (audioMuteState.value === MuteState.enabled) {
            toggleAudio();
            setAudioForceMuted();
          }
          break;
        case MuteType.VIDEO:
          if (videoMuteState.value === MuteState.enabled) {
            toggleVideo();
            setVideoForceMuted();
          }
          break;
      }
    });

    await MeetingsApi.getInstance().registerRequestUnmuteCallback((muteType: MuteType) => {
      switch (muteType) {
        case MuteType.AUDIO:
          if (audioMuteState.value !== MuteState.enabled) {
            setAudioRequestUnmute();
          }
          break;
        case MuteType.VIDEO:
          if (videoMuteState.value !== MuteState.enabled) {
            setVideoRequestUnmute();
          }
          break;
      }
    });

    await MeetingsApi.getInstance().registerForceKickCallback(() => {
      leaveMeeting();
    });

    MeetingsApi.getInstance().setStreamListener(new StreamListenerImpl(nodeDetails));

    // Get our user or guest ID (if we're a guest, we should have a guest token that was retrieved when we loaded the
    // meeting route
    const userId: string | undefined = userStore.currentUserId ?? userStore.guestMemberId(payload.trackId);

    // Does this work? Until we've joined the meeting, we won't be sending pstatus updates
    if (userId) {
      await updateDisplayName(userId);
    }

    // set media join preferences based on local storage or user details if there are any
    const localstorageAudio = localStorage.getItem('localAudioMuted');
    const localstorageVideo = localStorage.getItem('localVideoMuted');
    if (localstorageAudio) {
      setJoinAudioMuted(localstorageAudio === 'true');
    }
    if (localstorageVideo && !payload.audioOnly) {
      setJoinVideoMuted(localstorageVideo === 'true');
    }

    const currentUser: FullUserDetails | null = userStore.currentUser;
    if (currentUser) {
      if (!payload.audioOnly) {
        setJoinVideoMuted(currentUser.joinWithMutedVideo ?? false);
      }
      setJoinAudioMuted(currentUser.joinWithMutedAudio ?? false);
    }

    // TODO: This can race the above registerMeetingsCallback callback and error. initMeeting not fully sync?
    setMediaPreferences();

    setMeetingTrackId(payload.trackId);
    setInMeeting(OngoingMeetingState.IN_PRE_MEET);

    // We can't use User/isMeetingGuest getter here as it relies on the meeting track ID that won't be set
    // until this method returns
    const isMeetingGuest = userStore.isGuestUser || userStore.isGuestMember(payload.trackId);
    if (!isMeetingGuest) {
      chatMessagesStore.handleNewChatTrackId(payload.trackId);
      DataWorker.instance().setMeetingTrackId(payload.trackId, MeetingStatus.JOINING_MEETING);
    }

    if (waitingRoomPoll.value !== null) {
      window.clearTimeout(waitingRoomPoll.value);
    }

    setLastKnownLockState(MeetingLockType.UNLOCKED);
    setLockStateUpdateExecuted(false);
    setRequestedLockState(null);

    // Initial trigger is done immediately. And we rely on the fact that the task
    // run by the setTimeout will only start on the next tick.
    const timer = setTimeout(async () => {
      refreshWaitingRoomData();
    }, 0);
    setWaitingRoomPoll(timer);

    return {
      screenshareSupported: MeetingsApi.getInstance().screenShareSupported(),
    };
  }

  async function updateDisplayName(displayName: string): Promise<void> {
    MeetingsApi.getInstance().updateDisplayName(displayName);
  }

  function setMediaPreferences(): void {
    MeetingsApi.getInstance().setLocalAudio(!joinAudioMuted.value);
    MeetingsApi.getInstance().setLocalVideo(!joinVideoMuted.value);
  }

  async function joinMeeting(): Promise<void> {
    await MeetingsApi.getInstance().joinMeeting();

    if (!meetingTrackId.value) {
      log.debug('joinMeeting: meetingTrackId not set. Data worker endpoint and meeting store state left untouched');
      return;
    }

    const isMeetingGuest = userStore.isMeetingGuestOrGuestMember;
    if (!isMeetingGuest) {
      DataWorker.instance().setMeetingTrackId(meetingTrackId.value, MeetingStatus.IN_MEETING);
    }

    setInMeeting(OngoingMeetingState.IN_MEETING);
    toggleSettings(false);
    setMultitaskMeetingView(false);
  }

  async function handleMeetingGuestLoggedIn(meetingGuestLoggedInDetails: MeetingGuestLoggedInDetails): Promise<void> {
    const chatMessagesStore = useChatMessagesStore(pinia);
    await updateDisplayName(meetingGuestLoggedInDetails.userId);
    const ongoingMeetingState = inMeeting.value;

    const trackId: string | null = meetingTrackId.value;
    if (!trackId) {
      return;
    }

    // Send a meeting status update corresponding to the state the ongoing meeting was in before logging in
    let meetingStatus: MeetingStatus;
    switch (ongoingMeetingState) {
      case OngoingMeetingState.NO_MEETING:
        meetingStatus = MeetingStatus.NOT_IN_MEETING;
        break;
      case OngoingMeetingState.IN_PRE_MEET:
        meetingStatus = MeetingStatus.JOINING_MEETING;
        break;
      case OngoingMeetingState.IN_MEETING:
        meetingStatus = MeetingStatus.IN_MEETING;
        break;
      default:
        meetingStatus = MeetingStatus.NOT_IN_MEETING;
    }

    // We only want to clear the guestOnlineStatus and remove the guest track if we are a track member
    if (meetingGuestLoggedInDetails.clearGuestStatus) {
      trackOnlineStatusesStore.clearGuestOnlineStatuses(trackId);
      tracksStore.deleteGuestTrack(trackId);
    }

    // If we'd been logged in when joining the meeting, there would have been a meeting online state update, and a
    // chat track update (see: initMeeting), so do those here
    await DataWorker.instance().setActiveTrackId(trackId, undefined);
    const isMeetingGuest = userStore.isGuestUser || userStore.isGuestMember(trackId);
    if (!isMeetingGuest) {
      chatMessagesStore.handleNewChatTrackId(trackId);
      // This will send an initial online status update with the meeting status; the guest online status is handled
      // separately, so don't do this for a guest.
      DataWorker.instance().setMeetingTrackId(trackId, meetingStatus);
    }
  }

  function createSoundMeter(): void {
    MeetingsApi.getInstance().createSoundMeter((volume: number) => {
      const storeMic: number = Math.max(2, volume);
      setMicVolume(storeMic);
    });
  }

  function destroySoundMeter(): void {
    MeetingsApi.getInstance().destroySoundMeter();
  }

  async function leaveMeeting(): Promise<void> {
    const chatMessagesStore = useChatMessagesStore(pinia);
    MeetingsApi.getInstance().leaveMeeting();

    if (!meetingTrackId.value) {
      log.debug('leaveMeeting: meetingTrackId not set. Data worker endpoint and meeting store state left untouched');
      return;
    }
    const isMeetingGuest = userStore.isMeetingGuestOrGuestMember;
    if (!isMeetingGuest) {
      // This will send an initial online status update with the meeting status; the guest online status is handled
      // separately, so don't do this for a guest.
      DataWorker.instance().setMeetingTrackId(meetingTrackId.value, MeetingStatus.NOT_IN_MEETING);
      chatMessagesStore.handleClosedChatTrackId(meetingTrackId.value);
    } else {
      const trackEntriesStore = useTrackEntriesStore(pinia);
      trackEntriesStore.setGuestMeetingRecordEntry(null);
    }

    DataWorker.instance().dispatch('Tracks/sendCallMessage', meetingTrackId.value, CallMessageType.CALL_CANCEL);

    if (selfViewTimer.value !== -1) {
      window.clearInterval(selfViewTimer.value);
      setSelfViewTimer(-1);
    }
    if (activeViewTimer.value !== -1) {
      window.clearInterval(activeViewTimer.value);
      setActiveViewTimer(-1);
    }
    if (waitingRoomPoll.value !== null) {
      window.clearTimeout(waitingRoomPoll.value);
      setWaitingRoomPoll(null);
    }
    setInMeeting(OngoingMeetingState.NO_MEETING);
    setSelfVideoLocation({ top: 0, width: 0, height: 0, left: 0 });
    setActiveVideoLocation({ top: 0, width: 0, height: 0, left: 0 });
    setMeetingTrackId(null);
    setMultitaskMeetingView(false);
    setLastKnownLockState(MeetingLockType.UNLOCKED);
    setLockStateUpdateExecuted(false);
    setRequestedLockState(null);
  }

  /**
   * This method should be called only by the timer task (as calling this causes a timer re-schedule).
   */
  async function refreshWaitingRoomData(): Promise<void> {
    // To be on the safe side we check the timer handle to see if it was forced to null.
    // This will protect us against the situation where:
    //   - the timer triggered the task
    //   - but the task is async so not executed immediately
    //   - timer is cancelled
    //   - and finally the task is executed
    if (waitingRoomPoll.value) {
      // The "clearTimeout" protects against someone accidentally calling this method outside of the timer context
      // (as that would cause multiple timers to then be fired in parallel).
      clearTimeout(waitingRoomPoll.value);

      try {
        if (inMeeting.value === OngoingMeetingState.IN_MEETING && isMeetingCoordinator.value) {
          const data: WaitingRoomData = await DataWorker.instance().dispatch('Meetings/getWaitingRoomData',
            meetingTrackId.value);

          const waitingUsers: WaitingRoomUserDetails[] = await populateWaitingUserDetails(
            data.users, onlineMeetingTrackMembers.value);
          setLastKnownLockState(data.state);
          setUsersInWaitingRoom(waitingUsers);
          if (lockStateUpdateExecuted.value === true) {
            setLockStateUpdateExecuted(false);
            setRequestedLockState(null);
          }
        }
      } finally {
        // Schedule the next refresh unless cancelled...
        if (waitingRoomPoll.value) {
          const timer = setTimeout(async () => {
            refreshWaitingRoomData();
          }, 5000);
          setWaitingRoomPoll(timer);
        }
      }
    }
  }

  /**
   * Action to update the conference access control mode whilst
   * keeping the local view of it consistent (whilst the change is ongoing).
   */
  async function updateAccessControlMode(lockType: MeetingLockType): Promise<void> {
    if (!meetingTrackId.value) {
      log.debug('Conference must have ended (or it is not started). Cannot update the access mode.');
      return;
    }

    // The UI checks if the user is allowed to change the access mode.
    // So by default assumes it worked (so that the local view is consistent
    // why what the user thinks)...
    setRequestedLockState(lockType);
    setLockStateUpdateExecuted(false);

    await DataWorker.instance().dispatch('Meetings/updateMeetingAccessControl',
      meetingTrackId.value, lockType).finally(() => {
      // Once the update is confirmed done, we flag that it has been done:
      setLockStateUpdateExecuted(true);
      // But we don't reset the override yet, as we need to wait for the next waiting
      // room data poll to complete...
    });
  }

  async function sendMeetingInvite(invitePayload: InvitePayload): Promise<void> {
    await DataWorker.instance().dispatch('Tracks/sendMeetingInvite', invitePayload);
  }

  async function attachActiveSpeakerView(stream: HTMLDivElement): Promise<void> {
    if (BrowserDetection.isIosApp()) {
      const check = () => {
        const payload = generateMeetingLocation(stream);
        if (payload.top !== activeVideoLocation.value.top || payload.left !== activeVideoLocation.value.left ||
          payload.height !== activeVideoLocation.value.height || payload.width !== activeVideoLocation.value.width) {
          setActiveVideoLocation(payload);
        }
      };
      if (activeViewTimer.value !== -1) {
        window.clearInterval(activeViewTimer.value);
      }
      const timer = window.setInterval(check, 100);
      setActiveViewTimer(timer);
    }
    await MeetingsApi.getInstance().attachActiveSpeakerVideo(stream);
  }

  async function attachLocalView(stream: HTMLDivElement): Promise<void> {
    if (BrowserDetection.isIosApp()) {
      const check = () => {
        const payload = generateMeetingLocation(stream);
        if (payload.top !== selfVideoLocation.value.top || payload.left !== selfVideoLocation.value.left ||
          payload.height !== selfVideoLocation.value.height || payload.width !== selfVideoLocation.value.width) {
          setSelfVideoLocation(payload);
        }
      };
      if (selfViewTimer.value !== -1) {
        window.clearInterval(selfViewTimer.value);
      }
      const timer = window.setInterval(check, 100);
      setSelfViewTimer(timer);
    }
    await MeetingsApi.getInstance().attachSelfVideo(stream);
  }

  function setAudioOutput(newSelection: string): void {
    for (const device of speakers.value) {
      if (device.deviceId === newSelection) {
        MeetingsApi.getInstance().setOutput(device.deviceId, device.deviceName);
        return;
      }
    }
  }

  function setAudioInput(newSelection: string): void {
    for (const device of audioDevices.value) {
      if (device.deviceId === newSelection) {
        MeetingsApi.getInstance().setMicrophone(device.deviceId, device.deviceName);
        return;
      }
    }
  }

  async function toggleAudio(): Promise<void> {
    MeetingsApi.getInstance().toggleLocalAudio();
    if (inMeeting.value === OngoingMeetingState.IN_PRE_MEET) {
      // if we're not in the meeting yet the self participant won't exist for meetings to supply the mute state
      setJoinAudioMuted(!joinAudioMuted.value);
      localStorage.setItem('localAudioMuted', joinAudioMuted.value.toString());
    }
  }

  function toggleVideo(): void {
    MeetingsApi.getInstance().toggleLocalVideo();
    if (inMeeting.value === OngoingMeetingState.IN_PRE_MEET) {
      // if we're not in the meeting yet the self participant won't exist for meetings to supply the mute state
      setJoinVideoMuted(!joinVideoMuted.value);
      localStorage.setItem('localVideoMuted', joinVideoMuted.value.toString());
    }
  }

  function toggleScreenShare(): void {
    if (isScreenSharing.value) {
      MeetingsApi.getInstance().endScreenShare();
    } else {
      MeetingsApi.getInstance().startScreenShare();
    }
  }

  // Toggles between the first two video input devices. Intended for use on mobile clients with front &
  // back camera.
  function toggleCamera(): void {
    if (!videoDevices.value || videoDevices.value.length === 0) {
      return;
    }

    const currentDevice = videoDevices.value.find((device) => device.selected);
    if (!currentDevice) {
      return;
    }

    const selectedIndex = videoDevices.value.indexOf(currentDevice);
    const newDevice = videoDevices.value[(selectedIndex + 1) % 2];
    setVideoInput(newDevice.deviceId);
  }

  const onlineMeetingTrackMembers: ComputedRef<LimitedUserDetails[]> = computed(() => {
    // If we are logged in and not a track guest, we'll have user online statuses here
    const onlineTrackStatus: TrackOnlineStatus = trackOnlineStatusesStore.statuses[meetingTrackId.value ?? ''] ?? [];

    // If we are a guest, we'll have user online statuses here
    const guestOnlineTrackStatus: TrackOnlineStatus =
      trackOnlineStatusesStore.guestOnlineStatuses[meetingTrackId.value ?? ''] ?? [];

    const allOnlineStatuses = [...(onlineTrackStatus?.online ?? []), ...(guestOnlineTrackStatus?.online ?? [])];
    if (allOnlineStatuses.length === 0) {
      return [];
    }
    const userDetailsRecords: Record<string, LimitedUserDetails> = userStore.userDetailsRecordForMeetingTrackMembers;
    const userDetailsForActiveTrack: LimitedUserDetails[] = Object.values(userDetailsRecords);
    const onlineUserDetails: LimitedUserDetails[] = [];

    for (const onlineMember of allOnlineStatuses) {
      const onlineUser = userDetailsForActiveTrack.find(user => user.id === onlineMember.userId);
      if (onlineUser) {
        // Online track member who is not a guest
        onlineUserDetails.push(onlineUser);
      } else if (onlineMember.guestId) {
        // Online member is a guest. If we are a guest of the meeting, check guest tracks
        const guestOfMeetingTrack = userStore.isGuestMember(meetingTrackId.value ?? '');

        const track: Track | undefined = guestOfMeetingTrack
          ? tracksStore.guestTrack(meetingTrackId.value ?? '')
          : tracksStore.tracks[meetingTrackId.value ?? ''];
        const guestUserDetails: LimitedUserDetails = {
          email: '',
          id: onlineMember.guestId,
          displayName: guestDisplayName(onlineMember.name ?? '', track),
          color: onlineMember.color,
        };

        onlineUserDetails.push(guestUserDetails);
      }
    }

    return onlineUserDetails;
  });

  async function getParticipantDetails(participantId: string): Promise<LimitedUserDetails | undefined> {
    const currentUser: FullUserDetails | null = userStore.currentUser;
    if (currentUser && currentUser.id === participantId) {
      return { ...currentUser };
    }

    const onlineMeetingMembers: LimitedUserDetails[] = onlineMeetingTrackMembers.value;
    for (const userDetails of onlineMeetingMembers) {
      if (userDetails.id === participantId) {
        // TODO guest here won't be a full user details?
        return userDetails;
      }
    }

    // There aren't any user details to get if it's a PSTN participant
    if (MeetingsHelper.isPstn(participantId)) {
      return;
    }

    // We're getting user details for a guest (either as a guest ourselves, or as a member)
    const guestMemberId = meetingTrackId.value ? userStore.guestMemberId(meetingTrackId.value) : undefined;
    if (guestMemberId) {
      // If we're a guest, we won't have the participant's details to hand, as we're not allowed to get that track
      // data, so let's make a request to the separate guest user details endpoint
      const payload: GetUserDetailsAsGuestPayload = {
        userId: participantId,
        trackId: meetingTrackId.value ?? '',
        guestId: guestMemberId,
      };
      return await userStore.getUserDetailsAsGuest(payload);
    } else if (currentUser) {
      const userDetails = await userStore.fetchUserDetails(participantId);
      const track: Track = tracksStore.tracks[meetingTrackId.value ?? ''];
      const guestUserDetails: LimitedUserDetails = {
        email: userDetails?.email ?? '',
        id: userDetails?.id ?? '',
        displayName: guestDisplayName(userDetails?.displayName ?? '', track),
      };
      return guestUserDetails;
    }
  }

  async function scheduleMeeting(details: ScheduledMeeting): Promise<ScheduledMeeting | void> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      const meeting: ScheduledMeeting = await DataWorker.instance().dispatch('Meetings/scheduleMeeting', details);
      if (meeting.entry) {
        const trackEntriesStore = useTrackEntriesStore(pinia);
        trackEntriesStore.setEntries({
          entries: [meeting.entry],
          fullRefresh: false,
          setOGPData: false
        });

        await userStore.refreshCalendar();
      }

      return meeting;
    } catch (error) {
      log.error('Error creating meeting: ' + error);
      throw new Error('Failed to schedule meeting');
    }
  }

  async function getScheduleMeeting(details: ScheduledMeetingPayload): Promise<ScheduledMeeting | void> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      return await DataWorker.instance().dispatch('Meetings/getScheduleMeeting', details);
    } catch (error) {
      log.error('Error getting meeting: ' + error);
      throw new Error('Failed to get schedule meeting');
    }
  }

  async function getPastMeeting(details: PastMeetingPayload): Promise<PastMeetingDetails | undefined> {
    const entryTextFilesStore = useEntryTextFilesStore(pinia);
    const textFileContents: unknown[] | undefined = await entryTextFilesStore.getTextFileContentsForEntry(details);
    if (textFileContents?.length) {
      // There should only be one text file per meeting_record entry
      const meeting = textFileContents[0] as PastMeetingDetails;
      meeting.entryId = details.entryId;
      meeting.trackId = details.trackId;
      return meeting;
    }
  }

  async function updateRecordMeeting(payload: RecordingPayload): Promise<void> {
    try {
      await DataWorker.instance().dispatch('Meetings/updateMeetingRecording', payload);
    } catch (error) {
      log.error('Error while updating meeting recording: ' + error);
    }
  }

  function setVideoInput(newSelection: string): void {
    for (const device of videoDevices.value) {
      if (device.deviceId === newSelection) {
        MeetingsApi.getInstance().setCamera(device.deviceId, device.deviceName);
        return;
      }
    }
  }

  async function startPresentation(details: {trackId: string; entryId: string}):
    Promise<PresentationDetail | undefined> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      return await DataWorker.instance().dispatch('Meetings/startPresentation', details.trackId, details.entryId);
    } catch (error) {
      log.error('Error starting presentation: ' + error);
    }
  }

  async function stopPresentation(details: {trackId: string; entryId: string}):
    Promise<PresentationDetail | undefined> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      return await DataWorker.instance().dispatch('Meetings/stopPresentation', details.trackId, details.entryId);
    } catch (error) {
      log.error('Error stopping presentation: ' + error);
    }
  }

  async function forceEndPresentation(details: {trackId: string; entryId: string}):
    Promise<PresentationDetail | undefined> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      return await DataWorker.instance().dispatch('Meetings/forceEndPresentation', details.trackId, details.entryId);
    } catch (error) {
      log.error('Error force ending presentation: ' + error);
    }
  }

  async function forceMute(details: { muteType: MuteType, nodeId: string }): Promise<void> {
    if (meetingTrackId.value && inMeeting.value === OngoingMeetingState.IN_MEETING) {
      await DataWorker.instance()
        .dispatch('Tracks/sendForceMute', meetingTrackId.value, details.muteType, details.nodeId);
    }
  }

  async function requestUnmute(details: { muteType: MuteType, nodeId: string }): Promise<void> {
    if (meetingTrackId.value && inMeeting.value === OngoingMeetingState.IN_MEETING) {
      MeetingsApi.getInstance().requestUnmute(details.nodeId, details.muteType);
    }
  }

  async function forceKick(details: { nodeId: string, memberId: string }): Promise<void> {
    if (meetingTrackId.value && inMeeting.value === OngoingMeetingState.IN_MEETING) {
      await DataWorker.instance()
        .dispatch('Tracks/sendForceKick', meetingTrackId.value, details.nodeId, details.memberId);
    }
  }

  async function prepareDefaultMeetingEntryAsGuest(trackId: string): Promise<TrackEntry | null> {
    let meetingEntry: TrackEntry | null = defaultGuestMeetingEntries.value[trackId];
    if (meetingEntry) {
      return meetingEntry;
    }

    const guestToken: UserToken | undefined = userStore.guestTokenForTrack(trackId);
    if (!guestToken) {
      return null;
    }

    const trackEntriesStore = useTrackEntriesStore(pinia);
    const entryId: string | undefined = trackEntriesStore.defaultMeetingEntryId;
    if (entryId) {
      meetingEntry = await trackEntriesStore.getEntryAsGuest({
        trackId,
        entryId,
        guestToken,
      });
    }
    if (meetingEntry) {
      defaultGuestMeetingEntries.value[trackId] = meetingEntry;
    }
    return meetingEntry;
  }

  async function getOrCreateThirdPartyMeetingDetails(payload: { appId: string, trackId: string, guestId?: string }):
    Promise<ThirdPartyMeetingDetails> {
    const response: ThirdPartyMeetingDetails = await DataWorker.instance().dispatch(
      'Meetings/getOrCreateThirdPartyMeetingDetails', payload.appId, payload.trackId, payload.guestId);
    setThirdPartyMeetingDetails(response);
    return response;
  }

  function updateVideoLayout(layout: string | { requestedVideoLayout: string; userRequested?: boolean }): void {
    const meetingsApi = MeetingsApi.getInstance();
    let toRequest: string;
    let userRequest: boolean = false;
    if (typeof layout === 'string') {
      toRequest = layout;
    } else {
      toRequest = layout.requestedVideoLayout;
      userRequest = !!layout.userRequested;
    }
    meetingsApi.updateLayout(toRequest);
    // TODO: Should this sync from the API?
    requestedVideoLayout.value = toRequest;
    if (userRequest) {
      userRequestedVideoLayout.value = toRequest;
    }
  }

  function restoreUserRequestedVideoLayout(): void {
    if (userRequestedVideoLayout.value) {
      const meetingsApi = MeetingsApi.getInstance();
      meetingsApi.updateLayout(userRequestedVideoLayout.value);
      requestedVideoLayout.value = userRequestedVideoLayout.value;
    }
  }

  function toggleSettings(show: boolean): void {
    showSettings.value = show;
  }

  function secondaryMenuExpanded(menuExpanded: boolean): void {
    isSecondaryMenuExpanded.value = menuExpanded;
  }

  function setMicVolume(volume: number): void {
    micVolume.value = volume;
  }

  function setMeetingsState(newState: MeetingsState): void {
    if (meetingState.value) {
      patchObject(meetingState.value as unknown as Record<string, unknown>, asRecord(newState), true);
    } else {
      meetingState.value = newState;
    }
  }

  /* This should never be called locally from within Fender and should only ever be set via the
  participant callback from the Meetings API. */
  function setParticipantState(newState: ParticipantState): void {
    const localParticipant = newState.participants.find((p) => p.nodeId === meetingState.value?.localNodeId);
    if (localParticipant) {
      // update meeting join preferences
      localStorage.setItem('localAudioMuted', localParticipant.audioMuted.toString());
      localStorage.setItem('localVideoMuted', localParticipant.videoMuted.toString());
    }

    if (participantState.value) {
      patchObject(participantState.value as unknown as Record<string, unknown>, asRecord(newState), true);
    } else {
      participantState.value = newState;
    }
  }

  function setAudioForceMuted(newState: boolean = true): void {
    audioForceMuted.value = newState;
  }

  function setVideoForceMuted(newState: boolean = true): void {
    videoForceMuted.value = newState;
  }

  function setAudioRequestUnmute(newState: boolean = true): void {
    audioRequestUnmute.value = newState;
  }

  function setVideoRequestUnmute(newState: boolean = true): void {
    videoRequestUnmute.value = newState;
  }

  function setMeetingLocation(newLocation: MeetingLocation | undefined): void {
    meetingLocation.value = newLocation;
  }

  function setActiveVideoLocation(newLocation: MeetingLocation): void {
    selfVideoLocation.value = newLocation;
  }

  function setSelfVideoLocation(newLocation: MeetingLocation): void {
    activeVideoLocation.value = newLocation;
  }

  function setSelfViewTimer(timer: number): void {
    selfViewTimer.value = timer;
  }

  function setActiveViewTimer(timer: number): void {
    activeViewTimer.value = timer;
  }

  function setJoinAudioMuted(isMuted: boolean): void {
    joinAudioMuted.value = isMuted;
    audioForceMuted.value = false;
    audioRequestUnmute.value = false;
  }

  function setJoinVideoMuted(isMuted: boolean): void {
    joinVideoMuted.value = isMuted;
    videoForceMuted.value = false;
    videoRequestUnmute.value = false;
  }

  function setMeetingTrackId(meetingTrackIdToSet: string | null): void {
    meetingTrackId.value = meetingTrackIdToSet;
  }

  function setInMeeting(newState: OngoingMeetingState): void {
    inMeeting.value = newState;
  }

  function setMultitaskMeetingView(multitaskMeetingViewToSet: boolean): void {
    multitaskMeetingView.value = multitaskMeetingViewToSet;
  }

  function setWaitingRoomPoll(timer: ReturnType<typeof setTimeout> | null) {
    waitingRoomPoll.value = timer;
  }

  function setRequestedLockState(requestedLockStateToSet: MeetingLockType | null) {
    requestedLockState.value = requestedLockStateToSet;
  }

  function setLastKnownLockState(lastKnownLockStateToSet: MeetingLockType) {
    lastKnownLockState.value = lastKnownLockStateToSet;
  }

  function setLockStateUpdateExecuted(lockStateUpdateExecutedToSet: boolean) {
    lockStateUpdateExecuted.value = lockStateUpdateExecutedToSet;
  }

  function setUsersInWaitingRoom(usersInWaitingRoomToSet: WaitingRoomUserDetails[]) {
    usersInWaitingRoom.value = usersInWaitingRoomToSet;
  }

  function setInitialised(initialised: boolean): void {
    log.info('Meetings API initialised');
    meetingApiInitialised.value = initialised;
  }

  function setThirdPartyMeetingTrackId(meetingTrackId: string | null): void {
    thirdPartyMeetingTrackId.value = meetingTrackId;
  }

  function setThirdPartyMeetingDetails(details: ThirdPartyMeetingDetails): void {
    Vue.set(thirdPartyMeetingDetailsByTrack.value, details.trackId, details);
    if (details.trackId === thirdPartyMeetingTrackId.value && details.status === 'ENDED') {
      thirdPartyMeetingTrackId.value = null;
    }
  }

  function generateMeetingLocation(stream: HTMLDivElement): MeetingLocation {
    if (stream.offsetParent !== null) {
      const rect = stream.getBoundingClientRect();
      return {
        top: rect.top,
        height: rect.height,
        left: rect.left,
        width: rect.width,
      };
    } else {
      return { top: 0, left: 0, height: 0, width: 0 };
    }
  }

  return {
    meetingApiInitialised,
    inMeeting,
    meetingState,
    participantState,
    requestedVideoLayout,
    userRequestedVideoLayout,
    usersInWaitingRoom,
    showSettings,
    isSecondaryMenuExpanded,
    micVolume,
    audioForceMuted,
    videoForceMuted,
    audioRequestUnmute,
    videoRequestUnmute,
    meetingTrackId,
    thirdPartyMeetingTrackId,
    multitaskMeetingView,
    thirdPartyMeetingDetailsByTrack,
    videoLocation,
    videoMuteState,
    audioMuteState,
    audioDevices,
    videoDevices,
    speakers,
    trueActiveSpeaker,
    mediaReceived,
    currentlyRecording,
    layouts,
    allParticipants,
    localNodeId,
    isScreenSharing,
    passthroughNodeDetails,
    selfSharing,
    localMediaState,
    hasLocalMediaFailed,
    hasRemoteMediaFailed,
    currentVideoLayout,
    localNetworkBad,
    sharingParticipant,
    calculatePossibleGuests,
    allTenantUsers,
    meetingEntry,
    meetingRecordEntryForUserOrGuest,
    getDialInNumbers,
    meetingTrack,
    lockState,
    isMeetingCoordinator,
    defaultMeetingEntry,
    initMeeting,
    setMediaPreferences,
    joinMeeting,
    handleMeetingGuestLoggedIn,
    createSoundMeter,
    destroySoundMeter,
    leaveMeeting,
    updateAccessControlMode,
    sendMeetingInvite,
    attachActiveSpeakerView,
    attachLocalView,
    setAudioOutput,
    setAudioInput,
    toggleAudio,
    toggleVideo,
    toggleScreenShare,
    toggleCamera,
    onlineMeetingTrackMembers,
    getParticipantDetails,
    scheduleMeeting,
    getScheduleMeeting,
    getPastMeeting,
    updateRecordMeeting,
    setVideoInput,
    startPresentation,
    stopPresentation,
    forceEndPresentation,
    forceMute,
    requestUnmute,
    forceKick,
    prepareDefaultMeetingEntryAsGuest,
    getOrCreateThirdPartyMeetingDetails,
    updateVideoLayout,
    restoreUserRequestedVideoLayout,
    toggleSettings,
    secondaryMenuExpanded,
    setAudioForceMuted,
    setVideoForceMuted,
    setAudioRequestUnmute,
    setVideoRequestUnmute,
    setMeetingLocation,
    setMeetingTrackId,
    setInMeeting,
    setMultitaskMeetingView,
    setInitialised,
    setThirdPartyMeetingTrackId,
    setThirdPartyMeetingDetails
  };
});
