import log from 'loglevel';
import { onUnmounted, Ref, ref } from 'vue';

import { useUserIdleDetection } from '@/composables/app/UserIdleDetection';
import { useRoute } from '@/composables/router/VueRouter';
import { AppName } from '@/data/Branding';
import PushMessaging from '@/data/pushmessaging/PushMessaging';
import { register } from '@/registerServiceWorker';
import { safeToReload } from '@/router';

// True when an update has been activated (service worker level) and we need
// the web page to reload to pick up the change.
// This is shared with the Vue router so that it can use it to decide whether
// a route change should transparently use a page reload or the normal route transition.
export let serviceWorkerUpdated: boolean = false;

// 4 hours - the period to wait before checking if there's a new SW version available to download
const UPDATE_CHECK_PERIOD: number = 4 * 60 * 60 * 1000;

// 2 minutes - After activation we need to urgently reload the page if it was already controlled by the SW
//             (as the page code points to a cache that has now been flushed).
//             This should be safe as, in the first place, we only trigger activation when Fender is IDLE.
const URGENT_FORCE_RELOAD_PERIOD: number = 2 * 60 * 1000;

// 1 hour - the normal period to wait for a route change before trying to reload the current page
//          After activation we don't need to urgently refresh the page if it was NOT controlled by the SW
//          (as this is the case where the webpage loads directly from the server).
const NORMAL_FORCE_RELOAD_PERIOD: number = 1 * 60 * 60 * 1000;

// 2 hours - the period to wait before trying (and retrying) to activate an installed service worker
const ACTIVATION_RETRY_DELAY: number = 2 * 60 * 60 * 1000;

// 2 seconds - the period to wait before considering all objections to activate have been received.
//             Needs to be short enough to not be affected by user status change and long enough
//             that we can cope with the timers variability.
const REJECTIONS_CHECK_DELAY: number = 2 * 1000;

export function useServiceWorkerListener() {
  const route = useRoute();

  const { userIdle } = useUserIdleDetection();

  // Handle to the timer that will keep checking if we are ready to activate
  // a newly installed version of the code.
  const activationRetryIntervalHandle: Ref<number | null> = ref(null);

  // Handle to the timer that will keep trying to force reload the page once
  // a new version of the code has been activated.
  const reloadRetryIntervalHandle: Ref<number | null> = ref(null);

  // True when some other window/tab has rejected our request to activate.
  const activationRejected: Ref<boolean> = ref(false);

  // A marker that this tab determined that all tabs were ready for activation and sent a SKIP_WAITING message
  // Used to help distinguish between a forced activation, and an interupted activation
  const detectedReadyForActivation: Ref<boolean> = ref(false);

  /**
   * @returns True if the current client (the specific tab or window we handle) has an active user (i.e. not idle) or
   * the current page in use should not be reloaded (meetings, etc). False otherwise.
   */
  function currentWebViewBusy(): boolean {
    return !userIdle.value || !safeToReload(route);
  }

  function serviceWorkerRegistered(event: Event): void {
    const customEvent: CustomEvent = event as unknown as CustomEvent;
    const registration: ServiceWorkerRegistration = customEvent.detail;
    // NOTE: We could set the registration as a component attribute but the registration callback is
    // often called after the registration processing is done. This means the "installing" event will typically
    // occur before that one, so it makes the code more reliable to use the passed registration object than
    // relying on a component attribute that won't always be up to date...

    // Periodically check for SW updates. Browsers do this, but it's opaque as to how often and the period isn't
    // dictated by the spec.
    setInterval(() => {
      log.debug('Checking for SW update');
      try {
        registration.update();
      } catch (err) {
        log.error('Failed to check for SW update: ' + err, err);
      }
    }, UPDATE_CHECK_PERIOD);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function serviceWorkerInstalled(event: Event): void {
    // If access to registration is needed, do:
    // const customEvent: CustomEvent = event as unknown as CustomEvent;
    // const registration: ServiceWorkerRegistration = customEvent.detail;

    log.debug('App Side notified of SW installed and ready for activation.');

    // First do some clean-up...
    // Stops the force reload if there was one pending (i.e. following an activation)
    serviceWorkerUpdated = false;
    if (reloadRetryIntervalHandle.value) {
      window.clearInterval(reloadRetryIntervalHandle.value);
      reloadRetryIntervalHandle.value = null;
    }

    if (activationRetryIntervalHandle.value === null) {
      // Randomly offset the retry interval by +/-15 minutes to spread out load caused by many clients updating at once
      const offsetActivationRetry = ACTIVATION_RETRY_DELAY + (Math.random() * 30 * 60 * 1000) - (15 * 60 * 1000);
      // Create the activation retry interval if there isn't already one going on...
      activationRetryIntervalHandle.value = window.setInterval(preActivate, offsetActivationRetry);
    }
  }

  /**
   * Checks if we can activate the new update by asking the other window/tabs
   * if they objects to the activation.
   */
  function preActivate(): void {
    activationRejected.value = false;
    navigator.serviceWorker.getRegistration().then(registration => {
      if (registration) {
        if (!registration.waiting) {
          // No more SW to activate at present (was activated by another client).
          if (registration.installing) {
            // Another SW is coming up, so leave the timer running...
            log.debug('Delayed activation as a new SW already installing');
            return;
          } else {
            // Nothing to activate => Kill the timer
            if (activationRetryIntervalHandle.value) {
              window.clearInterval(activationRetryIntervalHandle.value);
              activationRetryIntervalHandle.value = null;
            }
            return;
          }
        }
        // There is still a Service Worker waiting to be activated
        registration.waiting.postMessage({ type: 'PRE_ACTIVATION_WARNING' });
        window.setTimeout(activateIfPossible, REJECTIONS_CHECK_DELAY);
      }
    });
  }

  /**
   * Try and check if we can activate the new update.
   * This will be called once we have given enough time to all tabs/windows
   * to object. If we have received no objections, then the activation will occur.
   */
  function activateIfPossible(): void {
    navigator.serviceWorker.getRegistration().then(registration => {
      if (!registration) {
        return;
      }
      if (!registration.waiting) {
        // No more SW to activate at present (was activated by another client).
        if (registration.installing) {
          // Another SW is coming up, so leave the timer running...
          log.debug('Delayed activation as a new SW already installing');
          return;
        } else {
          // Nothing to activate => Kill the timer
          if (activationRetryIntervalHandle.value) {
            window.clearInterval(activationRetryIntervalHandle.value);
            activationRetryIntervalHandle.value = null;
          }
          return;
        }
      }
      // Ok, we still have a SW waiting to be activated.
      // Check if any other client objected to its activation...
      if (activationRejected.value) {
        // Activation was rejected by another client (i.e. window/tab)
        // Let the timer try again later...
        log.debug(`SW activation rejected by another client (${AppName} tab/window)`);
        return;
      }
      // We are good to activate the waiting service worker...
      console.log(new Date() + ' - SW: Activating service worker...');
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      detectedReadyForActivation.value = true;
      if (activationRetryIntervalHandle.value) {
        window.clearInterval(activationRetryIntervalHandle.value);
        activationRetryIntervalHandle.value = null;
      }
    });
  }

  function onServiceWorkerUpdated(): void {
    serviceWorkerUpdated = true;

    if (navigator.serviceWorker.controller) {
      // This page is controlled by an old cache that has now been removed so we need to try and reload
      // ASAP (need to avoid another timer triggering the load of one of the now deleted JS scripts).
      if (!reloadPage()) {
        console.log(new Date() + ' - WARNING: New worker controller activated, but page reload deferred.');
        console.log(new Date() + ' - User IDLE status was: ' + userIdle.value);
        console.log(new Date() + ' - Status of safe to reload was: ' + safeToReload(route));
        console.log(new Date() + ' - Previously determined ready for activation: ' + detectedReadyForActivation.value);
        sendServiceWorkerForcedUpdateEvent();
        /*
        if (window.confirm(
          `${AppName} has been updated and needs to reload for you to continue using the application.\n` +
          `If you Cancel, you may find that ${AppName} does not work as expected until you reload.`)) {
          window.location.reload();
        }
        */

        // Enforce the page reload will occurs with an interval timer...
        reloadRetryIntervalHandle.value = window.setInterval(reloadPage, URGENT_FORCE_RELOAD_PERIOD);
      }
    } else {
      // The page is currently not using any service worker.
      // So we need to reload it (so that the SW activates and protects us against further server side changes)
      // But we can enforce it at a more leasurely pace (as there is no issues of cache having been deleted).
      reloadRetryIntervalHandle.value = window.setInterval(reloadPage, NORMAL_FORCE_RELOAD_PERIOD);
    }
  }

  /**
   * Called when one client (another fender tab/window) is about to activate a new service worker
   * and is checking if we have any objections to its activation.
   *
   * Activating a service worker will need to be quicky followed by a page reload
   * so we need to object to it if our user is active or if some special activities are
   * occuring (like an ongoing meeting).
   */
  function preActivationWarning(event: Event): void {
    const customEvent: CustomEvent = event as unknown as CustomEvent;
    const senderId: string = customEvent.detail;
    if (currentWebViewBusy()) {
      navigator.serviceWorker.getRegistration().then(registration => {
        if (registration) {
          // All service workers have the ability to transmit message (even the installing one)
          // So find one and use it...
          const serviceWorker = registration.active || registration.waiting || registration.installing;
          serviceWorker?.postMessage({ type: 'REJECT_ACTIVATION', target: senderId });
        }
      });
    }
  }

  /**
   * Method called when we asked other clients (Fender tab/windows) if we could activate the latest SW
   * but some client(s) are busy and objected to it.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function rejectActivation(event: Event): void {
    activationRejected.value = true;
  }

  function reloadPage(): boolean {
    if (currentWebViewBusy()) {
      log.debug('User active or in meeting => Delaying the forced page reload');
      return false;
    } else {
      log.debug('Reloading webpage because of new SW activation');
      window.location.reload();
      return true; // Needed as the reload is often not immediate...
    }
  }

  /**
   * Register with the Service Worker and put in place the corresponding events handler.
   *
   * It makes the bridge between the service worker listeners scope and the Vue component
   * scope hence the use of document.dispatchEvent...
   */
  function registerServiceWorker(): void {
    // See https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-pwa
    if (process.env.NODE_ENV === 'production') {
      log.debug('Registering Service Worker (PRODUCTION MODE)');
      register(`${process.env.BASE_URL}service-worker.js`, {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ready(registration: ServiceWorkerRegistration): void {
          // Per default message: For more details, visit https://goo.gl/AFskqB
          if (navigator.serviceWorker.controller) {
            console.log(new Date() + ' - SW: App is being served from cache by a service worker');
          } else {
            console.log(new Date() +
              ' - SW ready but not used by App at present... Requesting page to be reloaded when possible');
            document.dispatchEvent(new CustomEvent('serviceWorkerUpdated', { detail: registration }));
          }
        },
        registered(registration: ServiceWorkerRegistration): void {
          log.debug('SW: Service worker has been registered');
          PushMessaging.setRegistration(registration);
          document.dispatchEvent(new CustomEvent('serviceWorkerRegistered', { detail: registration }));
        },
        installed(registration: ServiceWorkerRegistration): void {
          console.log(new Date() + ' - SW: New content installed and waiting for activation');
          document.dispatchEvent(new CustomEvent('serviceWorkerInstalled', { detail: registration }));
        },
        updated(registration: ServiceWorkerRegistration): void {
          console.log(new Date() + ' - SW: Update has activated. Now waiting for the right moment to reload the page');
          document.dispatchEvent(new CustomEvent('serviceWorkerUpdated', { detail: registration }));
        },
        preActivationWarning(senderId: string): void {
          document.dispatchEvent(new CustomEvent('preActivationWarning', { detail: senderId }));
        },
        rejectActivation(): void {
          document.dispatchEvent(new Event('rejectActivation'));
        },
        error(error): void {
          log.error('SW: Error during service worker registration:', error);
        }
      });
    } else {
      log.debug('Service Worker Registration DISABLED (DEV MODE!)');
    }
  }

  function sendServiceWorkerForcedUpdateEvent() {
    // There will be an event per loaded tab
    const payload = {
      service: 'SERVICE_WORKER',
      type: 'SERVICE_WORKER_FORCED_UPDATE',
      action: 'SUCCESS',
      origin: 'CLIENT',
      message: JSON.stringify({
        detectedReadyForActivation: detectedReadyForActivation.value,
        idle: userIdle.value,
        route: route
      })
    };
    const req = new XMLHttpRequest();
    req.open('POST', '/be/api/event');
    req.setRequestHeader('content-type', 'application/json');
    req.send(JSON.stringify(payload));
  }

  onUnmounted(() => {
    document.removeEventListener('serviceWorkerRegistered', serviceWorkerRegistered);
    document.removeEventListener('serviceWorkerInstalled', serviceWorkerInstalled);
    document.removeEventListener('serviceWorkerUpdated', onServiceWorkerUpdated);
    document.removeEventListener('preActivationWarning', preActivationWarning);
    document.removeEventListener('rejectActivation', rejectActivation);
  });

  document.addEventListener('serviceWorkerRegistered', serviceWorkerRegistered);
  document.addEventListener('serviceWorkerInstalled', serviceWorkerInstalled);
  document.addEventListener('serviceWorkerUpdated', onServiceWorkerUpdated);
  document.addEventListener('preActivationWarning', preActivationWarning);
  document.addEventListener('rejectActivation', rejectActivation);
  registerServiceWorker();
}
