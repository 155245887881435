import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import { UI_VERSION } from '@/data/AppClient';
import { AppIntegration } from '@/data/datatypes/AppIntegration';
import { Calendar } from '@/data/datatypes/Calendar';
import ScheduledMeeting from '@/data/datatypes/meeting/ScheduledMeeting';
import { Member } from '@/data/datatypes/Member';
import { TrackOnlineStatus } from '@/data/datatypes/online/TrackOnlineStatus';
import { ExternalTenantUsersByPermission } from '@/data/datatypes/permissions/ExternalTenantUsersByPermission';
import { ChatAccessLevel, Track } from '@/data/datatypes/Track';
import { TrackingCodes } from '@/data/datatypes/tracking/TrackingCodes';
import { UserCalendarContact, UserCalendarContactDto } from '@/data/datatypes/UserCalendarContact';
import { GrantUserCredentialsRequest, UserCredentialsGrant } from '@/data/datatypes/UserCredentialsGrant';
import {
  DoNotDisturbUserRequest,
  ExternalUserDetails,
  FullUserDetails, GuestOnlineStatusDetails,
  LimitedUserDetails,
  TenantUserDetails,
  TenantUserStatus,
  UserContact,
} from '@/data/datatypes/UserDetails';
import { UserState } from '@/data/datatypes/UserState';
import { UserStatus } from '@/data/datatypes/UserStatus';
import UserToken from '@/data/datatypes/UserToken';
import Features from '@/data/Features';
import {
  getUserTokenFromLocalStorage,
  isTokenValid,
  removeLogoutMarkerFromLocalStorage,
  removeUserTokenFromLocalStorage,
  setUserTokenInLocalStorage,
} from '@/data/helpers/TokenHelper';
import { patchUsersWithContactInfo, sortContactArray, userToContact } from '@/data/helpers/UserHelper';
import { perfLog, perfMsg } from '@/data/log/PerformanceLog';
import { NavBarItem } from '@/data/NavBarItem';
import PushMessaging from '@/data/pushmessaging/PushMessaging';
import DataWorker from '@/data/storage/DataWorker';
import {
  getEnvironmentIdToUseForAppAuth,
  removeAppAuthTokenFromLocalStorage
} from '@/data/tasks/customviews/AppAuthHelper';
import { MiniApp, MiniAppPermission } from '@/data/tasks/MiniApp';
import { waitForTrackListUpdate } from '@/router';
import pinia from '@/stores';
import { useChatMessagesStore } from '@/stores/ChatMessages';
import { useConnectionStore } from '@/stores/Connection';
import { useMeetingStore } from '@/stores/Meeting';
import { useRouteStore } from '@/stores/Route';
import { asRecord, patchObject, setOrPatchObject } from '@/stores/StoreHelper';
import { DEFAULT_COLLABORATION_APP_ID } from '@/stores/Tasks';
import { useTrackMembersStore } from '@/stores/TrackMembers';
import { useTrackOnlineStatusesStore } from '@/stores/TrackOnlineStatuses';
import { useTracksStore } from '@/stores/Tracks';
import {
  GetUserDetailsAsGuestPayload,
  GuestTokenRequest,
  GuestTokenRequestType,
  GuestTokenResponse
} from '@/stores/User.types';
import ConnectionState from '@/workers/datatypes/ConnectionState';
import OnlineUserState from '@/workers/datatypes/OnlineUserState';

export const useUserStore = defineStore('User', () => {
  const trackMembersStore = useTrackMembersStore(pinia);
  const meetingStore = useMeetingStore(pinia);

  /** The timeout to apply to the online user state. If state is older than this (millis) then it will be ignored. */
  const TIMEOUT_ONLINE_USER_STATE: number = 60_000;
  const LOCAL_STORAGE_FIRST_LOGIN_PROCESSED_KEY: string = 'flp';
  const MAX_USER_DETAILS_ATTEMPTS: number = 3;

  const GUEST_TOKEN_LOCAL_STORAGE_PREFIX = 'guestTokenId-';

  let initialUserInStorage: UserToken | null = getUserTokenFromLocalStorage(false);

  const currentToken: Ref<UserToken | null> = ref(null);
  const currentUser: Ref<FullUserDetails | null> = ref(null);
  const tenantUsers: Ref<Record<string, TenantUserDetails>> = ref({});
  const externalTenantUsers: Ref<Record<string, ExternalUserDetails>> = ref({});
  const tenantWorkflowUser: Ref<LimitedUserDetails | null> = ref(null);
  const tenantAnonymousUser: Ref<LimitedUserDetails | null> = ref(null);
  const onlineUserState: Ref<OnlineUserState> = ref(new OnlineUserState());
  const externalTenantUsersByPermissionType: Ref<ExternalTenantUsersByPermission> = ref({});
  const navBarItems: Ref<NavBarItem[]> = ref([]);
  const miscellaneousUserDetails: Ref<Record<string, LimitedUserDetails>> = ref({});
  const hadExpiredTokenOnStartup: Ref<boolean> = ref(false);
  const calendarMeetings: Ref<ScheduledMeeting[]> = ref([]);
  const failedUserDetailsRequests: Ref<Record<string, number>> = ref({});
  const workflowUserPromise: Ref<Promise<LimitedUserDetails | null> | null> = ref(null);
  const anonymousUserPromise: Ref<Promise<LimitedUserDetails | null> | null> = ref(null);
  // `${domain}/${user}` -> trackId
  const userTrackMap: Ref<Record<string, string>> = ref({});
  // trackId -> UserToken
  const guestTokens: Ref<Record<string, UserToken>> = ref({});
  const guestDisplayName: Ref<string | null> = ref(null);
  const guestNameOnServer: Ref<string | null> = ref(null);
  const userCredentialsGrants: Ref<UserCredentialsGrant[]> = ref([]);

  const isUserTenantAdmin: ComputedRef<boolean> = computed(() => {
    return currentTokenIfValid.value?.tenantAdmin ?? false;
  });

  const isUserSystemAdmin: ComputedRef<boolean> = computed(() => {
    return currentTokenIfValid.value?.systemAdmin ?? false;
  });

  const isReportingUser: ComputedRef<boolean> = computed(() => {
    return currentTokenIfValid.value?.reportingUser ?? false;
  });

  const allUsers: ComputedRef<LimitedUserDetails[]> = computed(() => {
    return [...allTenantActiveUsers.value, ...allExternalTenantUsers.value];
  });

  const allTenantActiveUsers: ComputedRef<LimitedUserDetails[]> = computed(() => {
    return [...allTenantUsers.value.filter(
      (user: TenantUserDetails) => user.status !== 'DEACTIVATED' && user.status !== 'PENDING_DEACTIVATED')
    ];
  });

  const allTenantUsers: ComputedRef<TenantUserDetails[]> = computed(() => {
    return patchUsersWithContactInfo(Object.values(tenantUsers.value), currentUserContactMap.value);
  });

  const allExternalTenantUsers: ComputedRef<ExternalUserDetails[]> = computed(() => {
    return patchUsersWithContactInfo(Object.values(externalTenantUsers.value), currentUserContactMap.value);
  });

  const allExternalTenantUsersByPermissionType: ComputedRef<ExternalTenantUsersByPermission> = computed(() => {
    const result: ExternalTenantUsersByPermission = {};
    for (const permissionType in externalTenantUsersByPermissionType.value) {
      result[permissionType] = patchUsersWithContactInfo(externalTenantUsersByPermissionType.value[permissionType],
        currentUserContactMap.value);
    }
    return result;
  });

  const allConfirmedUsers: ComputedRef<LimitedUserDetails[]> = computed(() => {
    return [...confirmedTenantUsers.value, ...allExternalTenantUsers.value];
  });

  const confirmedTenantUsers: ComputedRef<TenantUserDetails[]> = computed(() => {
    return [...allTenantUsers.value.filter((user: TenantUserDetails) => user.status === TenantUserStatus.CONFIRMED)];
  });

  const confirmedTenantUsersAsContacts: ComputedRef<UserContact[]> = computed(() => {
    const systemContactMap: Map<string, UserContact> = new Map();
    confirmedTenantUsers.value.forEach((user) => {
      if (user.id !== currentUserId.value) {
        const contact: UserContact = userToContact(user);
        systemContactMap.set(user.id, contact);
      }
    });

    const contactArray: UserContact[] = [];

    const userContacts: UserContact[] = currentUser.value?.contacts ?? [];
    for (const userContact of userContacts) {
      if (userContact.deleted) {
        continue;
      }
      if (userContact.contactId && systemContactMap.has(userContact.contactId)) {
        // this contact represents additional properties added to a system user (ie, a tenant user),
        // so we want to "merge" the contact details into the system user details
        Object.assign(systemContactMap.get(userContact.contactId) ?? {}, userContact);
      }
    }

    contactArray.push(...systemContactMap.values());

    return sortContactArray(contactArray);
  });

  const allExternalTenantUsersAsContacts: ComputedRef<UserContact[]> = computed(() => {
    const systemContactMap: Map<string, UserContact> = new Map();
    allExternalTenantUsers.value.forEach((user) => {
      if (user.id !== currentUserId.value) {
        const contact: UserContact = userToContact(user);
        systemContactMap.set(user.id, contact);
      }
    });

    const contactArray: UserContact[] = [];

    const userContacts: UserContact[] = currentUser.value?.contacts ?? [];
    for (const userContact of userContacts) {
      if (userContact.deleted) {
        continue;
      }
      if (userContact.contactId && systemContactMap.has(userContact.contactId)) {
        // this contact represents additional properties added to a system user (ie, an external user),
        // so we want to "merge" the contact details into the system user details
        Object.assign(systemContactMap.get(userContact.contactId) ?? {}, userContact);
      }
    }

    contactArray.push(...systemContactMap.values());

    return sortContactArray(contactArray);
  });

  // Returns all confirmed tenant & external users as UserContact objects, in addition to any "personal" (non-system)
  // contacts that the current user has created.
  const contacts: ComputedRef<UserContact[]> = computed(() => {
    const systemContactMap: Map<string, UserContact> = new Map();
    allConfirmedUsers.value.forEach((user) => {
      if (user.id !== currentUserId.value) {
        const contact: UserContact = userToContact(user);
        systemContactMap.set(user.id, contact);
      }
    });

    const contactArray: UserContact[] = [];

    const userContacts: UserContact[] = currentUser.value?.contacts ?? [];
    for (const userContact of userContacts) {
      if (userContact.deleted) {
        continue;
      }
      if (userContact.contactId) {
        // this contact represents additional properties added to a system user (ie, a tenant or external user),
        // so we want to "merge" the contact details into the system user details
        const systemContact: UserContact | undefined = systemContactMap.get(userContact.contactId);
        if (systemContact) {
          Object.assign(systemContact, userContact);
        } else {
          log.warn(`Custom contact details found for ${userContact.contactId}, but no related system contact found`);
        }
      } else {
        // this is a "personal" contact, ie, a contact that is not related to a tenant/external user, so just
        // use it as-is
        contactArray.push(userContact);
      }
    }

    contactArray.push(...systemContactMap.values());

    return sortContactArray(contactArray);
  });

  // maps userId to UserContact
  const currentUserContactMap: ComputedRef<Map<string, UserContact>> = computed(() => {
    const contactMap = new Map();
    if (currentUser.value?.contacts) {
      currentUser.value.contacts.forEach(c => {
        if (c.contactId && !c.deleted) {
          contactMap.set(c.contactId, c);
        }
      });
    }
    return contactMap;
  });

  const calendarContacts: ComputedRef<UserCalendarContact[]> = computed(() => {
    const contacts: UserCalendarContactDto[] = currentUser.value?.calendarContactPreferences ?? [];
    const results: UserCalendarContact[] = [];
    if (contacts && contacts.length > 0) {
      for (const contact of contacts) {
        const userDetails = allKnownUserDetails.value[contact.contactId];
        if (userDetails) {
          results.push({
            id: contact.id,
            contact: userDetails,
            visible: contact.visible
          });
        } else {
          log.debug('Removed calendar contact - cannot find details for ' + contact.contactId);
        }
      }

      // We could also include the visibility in the sort order but this moves the contact
      // all over the place when the end user is probably just trying to check a given contact
      // (i.e. he is quickly enabling and then immediately disabling the visibility).
      // => So, in the end, decided to only use the alphabetical order.
      results.sort((first: UserCalendarContact, second: UserCalendarContact) => {
        const firstDisplayName: string = (first.contact.displayName?.trim() === ''
          ? first.contact.email : first.contact.displayName) ?? '';
        const secondDisplayName: string = (second.contact.displayName?.trim() === ''
          ? second.contact.email : second.contact.displayName) ?? '';
        if (firstDisplayName < secondDisplayName) {
          return -1;
        }
        if (firstDisplayName > secondDisplayName) {
          return 1;
        }
        return 0;
      });
    }
    return results;
  });

  const isCurrentTokenValid: ComputedRef<boolean> = computed(() => {
    const connectionStore = useConnectionStore(pinia);
    const connectionState: ConnectionState | undefined = connectionStore.connectionState;
    return !connectionState?.authFailure && isTokenValid(currentToken.value);
  });

  const currentTokenIfValid: ComputedRef<UserToken | null> = computed(() => {
    return isCurrentTokenValid.value ? currentToken.value : null;
  });

  const currentTokenARID: ComputedRef<string | null> = computed(() => {
    return currentToken.value?.arid ?? null;
  });

  const tenantId: ComputedRef<string | null> = computed(() => {
    return currentTokenIfValid.value?.tenantId ?? null;
  });

  const meetingUrl: ComputedRef<string> = computed(() => {
    if (currentToken.value && isCurrentTokenValid.value) {
      if (currentToken.value.meetingsUrl) {
        return currentToken.value.meetingsUrl;
      }
    }
    return 'https://meetings.cafex.com/';
  });

  const currentUserId: ComputedRef<string | null> = computed(() => {
    return currentUser.value ? currentUser.value.id : null;
  });

  const currentUserEmail: ComputedRef<string | null> = computed(() => {
    return currentUser.value ? currentUser.value.email : null;
  });

  const currentUserDetails: ComputedRef<FullUserDetails | null> = computed(() => {
    return currentUser.value;
  });

  const currentUserStatus: ComputedRef<UserStatus | null> = computed(() => {
    if (currentUser.value && currentUser.value.userStatusEmoji && currentUser.value.userStatusText) {
      return {
        emoji: currentUser.value.userStatusEmoji,
        text: currentUser.value.userStatusText,
        expiry: currentUser.value.userStatusExpiry ?? null
      };
    }
    return null;
  });

  const userDisplayName: ComputedRef<string | null> = computed(() => {
    return currentUser.value ? currentUser.value.displayName : null;
  });

  const userAvatar: ComputedRef<string | null> = computed(() => {
    if (currentUser.value && currentUser.value.avatar) {
      return currentUser.value.avatar;
    }
    return null;
  });

  const userColour: ComputedRef<string | null> = computed(() => {
    if (currentUser.value && currentUser.value.color) {
      return currentUser.value.color;
    }
    return null;
  });

  const displayDetailsForUsers: ComputedRef<Map<string, LimitedUserDetails>> = computed(() => {
    const result = new Map();
    allTenantUsers.value.forEach((user) => {
      result.set(user.id, user);
    });
    allExternalTenantUsers.value.forEach((user) => {
      result.set(user.id, user);
    });
    return result;
  });

  const userStatusesForUsers: ComputedRef<Map<string, UserStatus>> = computed(() => {
    const result = new Map();
    allTenantUsers.value.concat(allExternalTenantUsers.value).forEach((user) => {
      if (user.userStatusText) {
        result.set(user.id, { emoji: user.userStatusEmoji, text: user.userStatusText, expiry: user.userStatusExpiry });
      }
    });
    return result;
  });

  const allKnownUserDetails: ComputedRef<Record<string, LimitedUserDetails>> = computed(() => {
    const result: Record<string, LimitedUserDetails> = {};
    allTenantUsers.value.forEach((user) => {
      result[user.id] = user;
    });
    allExternalTenantUsers.value.forEach((user) => {
      result[user.id] = user;
    });
    Object.values(miscellaneousUserDetails.value).forEach((user) => {
      result[user.id] = user;
    });
    return result;
  });

  // returns a map with user email as key and userdetails as value
  const displayDetailsForUsersByEmail: ComputedRef<Map<string, LimitedUserDetails>> = computed(() => {
    const result = new Map();
    allTenantUsers.value.forEach((user) => {
      result.set(user.email, user);
    });
    allExternalTenantUsers.value.forEach((user) => {
      result.set(user.email, user);
    });
    return result;
  });

  const isGuestMember: ComputedRef<(trackId: string) => boolean> = computed(() => {
    return (trackId: string) => !!guestTokens.value[trackId];
  });

  const guestIdForTrack: ComputedRef<(trackId: string) => string | undefined> = computed(() => {
    return (trackId: string) => guestTokens.value[trackId]?.guestId;
  });

  const guestIdForCurrentMeeting: ComputedRef<string | undefined> = computed(() => {
    const meetingTrackId: string | null = meetingStore.meetingTrackId;
    return meetingTrackId ? guestTokens.value[meetingTrackId]?.guestId : undefined;
  });

  const guestTokenForCurrentMeeting: ComputedRef<UserToken | undefined> = computed(() => {
    const meetingTrackId: string | null = meetingStore.meetingTrackId;
    return meetingTrackId ? guestTokens.value[meetingTrackId] : undefined;
  });

  const guestName: ComputedRef<string | null> = computed(() => {
    return guestDisplayName.value || guestNameOnServer.value;
  });

  const guestTokenForTrack: ComputedRef<(trackId: string) => UserToken | undefined> = computed(() => {
    return (trackId: string) => guestTokens.value[trackId];
  });

  /**
   * Check if the current user is a guest to the system.
   * A guest user is someone who joins a meeting without being logged in.
   * A guest user is always a guest member, however a guest member isn't always a guest user.
   * @returns { boolean } false if there is a currently authenticated user, else true
   */
  const isGuestUser: ComputedRef<boolean> = computed(() => {
    return currentUser.value == null;
  });

  const userDetailsForActiveTrackMembers: ComputedRef<LimitedUserDetails[]> = computed(() => {
    return Object.values(userDetailsRecordForActiveTrackMembers.value);
  });

  const userDetailsForTrackMembers: ComputedRef<(trackId: string) => LimitedUserDetails[]> = computed(() => {
    return (trackId: string) => Object.values(userDetailsRecordForTrackMembers.value(trackId));
  });

  const userDetailsRecordForMeetingTrackMembers: ComputedRef<Record<string, LimitedUserDetails>> = computed(() => {
    const meetingId: string | null = meetingStore.meetingTrackId;
    let trackMembers: Member[] = [];
    if (meetingId) {
      const membersById: Record<string, Member> = trackMembersStore.members[meetingId ?? ''];
      if (membersById) {
        trackMembers = Object.values(membersById);
      }
    }
    return userDetailsRecordForMembers(trackMembers, currentUser.value, allTenantUsers.value,
      allExternalTenantUsers.value);
  });

  const userDetailsRecordForActiveTrackMembers: ComputedRef<Record<string, LimitedUserDetails>> = computed(() => {
    const trackMembers: Member[] = trackMembersStore.membersForActiveTrack;
    return userDetailsRecordForMembers(trackMembers, currentUser.value, allTenantUsers.value,
      allExternalTenantUsers.value);
  });

  // eslint-disable-next-line vue/max-len
  const userDetailsRecordForTrackMembers: ComputedRef<(trackId: string) => Record<string, LimitedUserDetails>> = computed(() => {
    return (trackId: string) => {
      const trackMembers: Member[] = trackMembersStore.membersForTrack(trackId);
      return userDetailsRecordForMembers(trackMembers, currentUser.value, allTenantUsers.value,
        allExternalTenantUsers.value);
    };
  });

  const confirmedUserDetailsForActiveTrackMembers: ComputedRef<Record<string, LimitedUserDetails>> = computed(() => {
    const trackMembers: Member[] = trackMembersStore.membersForActiveTrack;
    const confirmedTrackMembers = trackMembers.filter((member: Member) => member.confirmed);
    return userDetailsRecordForMembers(confirmedTrackMembers, currentUser.value, allTenantUsers.value,
      allExternalTenantUsers.value);
  });

  const userDetailsForChatTracks: ComputedRef<Record<string, LimitedUserDetails[]>> = computed(() => {
    const chatMessagesStore = useChatMessagesStore(pinia);
    const userDetailsByTrackId: Record<string, LimitedUserDetails[]> = {};
    for (const trackId of chatMessagesStore.chatTrackIds) {
      const membersById: Record<string, Member> = trackMembersStore.members[trackId];
      if (membersById) {
        const detailsByUserId: Record<string, LimitedUserDetails> = userDetailsRecordForMembers(
          Object.values(membersById), currentUser.value, allTenantUsers.value, allExternalTenantUsers.value);
        Vue.set(userDetailsByTrackId, trackId, Object.values(detailsByUserId));
      }
    }
    return userDetailsByTrackId;
  });

  const currentUserActiveTrackMembership: ComputedRef<Member | null> = computed(() => {
    if (currentUser.value === null) {
      return null;
    }
    const members: Member[] = trackMembersStore.membersForActiveTrack;
    if (members == null) {
      return null;
    }
    for (const member of members) {
      if (member.userId === currentUser.value.id) {
        return member;
      }
    }
    return null;
  });

  const currentUserChatTrackMemberships: ComputedRef<Record<string, Member>> = computed(() => {
    const chatMessagesStore = useChatMessagesStore(pinia);
    const membershipsByTrackId: Record<string, Member> = {};
    if (currentUser.value != null) {
      for (const trackId of chatMessagesStore.chatTrackIds) {
        const membersById: Record<string, Member> = trackMembersStore.members[trackId];
        if (membersById) {
          for (const member of Object.values(membersById)) {
            if (member.userId === currentUser.value.id) {
              membershipsByTrackId[trackId] = member;
            }
          }
        }
      }
    }
    return membershipsByTrackId;
  });

  const trackMembership: ComputedRef<(trackId: string) => Member | null> = computed(() => {
    return (trackId: string) => {
      const userId = currentUserId.value;
      if (userId === null) {
        return null;
      }
      const members: Member[] = trackMembersStore.membersForTrack(trackId);
      if (members === null) {
        return null;
      }
      for (const member of members) {
        if (member.userId === userId) {
          return member;
        }
      }
      return null;
    };
  });

  // Return undefined if we can't tell whether the member is confirmed or not.
  const isCurrentUserConfirmedMemberOfActiveTrack: ComputedRef<boolean | undefined> = computed(() => {
    const member: Member | null = currentUserActiveTrackMembership.value;
    if (member) {
      return member.confirmed;
    }
    return undefined;
  });

  const autoOpenLinks: ComputedRef<boolean> = computed(() => {
    return !!currentUser.value && !!currentUser.value.openLinks;
  });

  const onlineUserIds: ComputedRef<string[]> = computed(() => {
    const now: number = new Date().getTime();
    if (onlineUserState.value && onlineUserState.value.lastUpdated > (now - TIMEOUT_ONLINE_USER_STATE)) {
      return onlineUserState.value.onlineUserIds;
    }
    return [];
  });

  const userState: ComputedRef<UserState[]> = computed(() => {
    return currentToken.value?.userState ?? [];
  });

  const selectedUserId: ComputedRef<string | null> = computed(() => {
    const routeStore = useRouteStore(pinia);
    return routeStore.route?.params.userId ?? null;
  });

  const selectedUserDetails: ComputedRef<LimitedUserDetails | undefined> = computed(() => {
    if (selectedUserId.value) {
      return displayDetailsForUsers.value.get(selectedUserId.value);
    }
    return undefined;
  });

  const canConfigureApp: ComputedRef<(app: MiniApp) => boolean> = computed(() => {
    return (app: MiniApp) => {
      if (!canConfigureApps.value) {
        // User is not an app builder
        return false;
      }

      if (app.id === DEFAULT_COLLABORATION_APP_ID) {
        // Only tenant admins can configure the default collaboration app
        return isUserTenantAdmin.value;
      }

      if (app.tenantId !== tenantId.value) {
        // Prevent users configuring apps from other tenants
        return false;
      }

      if (isUserTenantAdmin.value) {
        // Tenant admins can configure any tenant level app in the same tenant
        return true;
      }

      // Check individual app permissions
      const configureAppPermissions: MiniAppPermission[] = app.permissions.filter(
        (permission: MiniAppPermission) => permission.type === 'CONFIGURE_APP');
      if (configureAppPermissions.length === 0) {
        // App configuration not restricted to particular users - all app builders can configure
        return true;
      }
      return configureAppPermissions.some((permission: MiniAppPermission) => permission.userId === currentUserId.value);
    };
  });

  const canConfigureApps: ComputedRef<boolean> = computed(() => {
    return isUserTenantAdmin.value || isUserFeatureEnabled.value(Features.APP_CONFIGURATION);
  });

  const isUserFeatureEnabled: ComputedRef<(feature: string) => boolean> = computed(() => {
    return (feature: string) => {
      if (!currentToken.value?.ufg) {
        const tracksStore = useTracksStore(pinia);
        if (!tracksStore.activeTrackId) {
          return false;
        }
        return isGuestFeatureEnabled.value(tracksStore.activeTrackId, feature);
      }
      for (const featureToggle of currentToken.value.ufg) {
        if (featureToggle === feature) {
          return true;
        }
      }
      return false;
    };
  });

  const isGuestOfActiveTrack: ComputedRef<boolean> = computed(() => {
    const tracksStore = useTracksStore();
    const activeTrackId = tracksStore.activeTrackId;
    if (activeTrackId === null) {
      return false;
    }

    return !!guestTokens.value[activeTrackId];
  });

  const isGuestFeatureEnabled: ComputedRef<(trackId: string, feature: string) => boolean> = computed(() => {
    return (trackId: string, feature: string) => {
      const guestToken: UserToken | undefined = guestTokens.value[trackId];
      if (!guestToken?.ufg) {
        return false;
      }
      for (const featureToggle of guestToken.ufg) {
        if (featureToggle === feature) {
          return true;
        }
      }

      return false;
    };
  });

  const currentUserCalendarMeetings: ComputedRef<ScheduledMeeting[]> = computed(() => {
    return calendarMeetings.value;
  });

  const currentUserCalendar: ComputedRef<Calendar | null> = computed(() => {
    return currentUser.value?.calendar ?? null;
  });

  const userTrackMapping: ComputedRef<(domain: string, user: string) => string | null> = computed(() => {
    return (domain: string, user: string) => {
      return userTrackMap.value[`${domain}/${user}`];
    };
  });

  /**
   * Gets the guest member ID of the track, if the current user is a guest of the track.
   * A guest member is someone who joins a meeting but isn't a member of the track.
   * A guest member may or may not be logged in. If they are not logged in, then they are also considered a guest user
   * @param {string} trackId The ID of the track to get the guest member ID of.
   * @returns {string | undefined} The member ID from the guest token, if one exists.
   */
  const guestMemberId: ComputedRef<(trackId: string) => string | undefined> = computed(() => {
    return (trackId: string) => {
      const token = guestTokens.value[trackId];
      if (token) {
        return token.guestId;
      }
      return undefined;
    };
  });

  /**
   * Returns true if the user is either a guest user, or is a guest member of the current meeting track.
   * If there is no current meeting, then it returns false
   */
  const isMeetingGuestOrGuestMember: ComputedRef<boolean> = computed(() => {
    const meetingTrackId = meetingStore.meetingTrackId;
    return !!meetingTrackId && (isGuestUser.value || isGuestMember.value(meetingTrackId));
  });

  const isThirdPartyMeetingGuestOrGuestMember: ComputedRef<boolean> = computed(() => {
    const thirdPartyMeetingTrackId = meetingStore.thirdPartyMeetingTrackId;
    return !!thirdPartyMeetingTrackId && (isGuestUser.value || isGuestMember.value(thirdPartyMeetingTrackId));
  });

  const appIntegrations: ComputedRef<AppIntegration[]> = computed(() => {
    if (!currentToken.value) {
      return [];
    }
    return currentToken.value.appIntegrations || [];
  });

  const isFirstLogin: ComputedRef<boolean> = computed(() => {
    if (currentToken.value && currentToken.value.firstLogin) {
      const firstLoginProcessed: string | null = localStorage.getItem(LOCAL_STORAGE_FIRST_LOGIN_PROCESSED_KEY);
      if (!firstLoginProcessed) {
        return true;
      }
      return !JSON.parse(firstLoginProcessed);
    }
    return false;
  });

  const trackingCodes: ComputedRef<TrackingCodes> = computed(() => {
    if (!currentToken.value && !guestTokens.value) {
      return {} as TrackingCodes;
    }
    if (isUserFeatureEnabled.value(Features.DISABLE_TRACKING)) {
      return {} as TrackingCodes;
    }
    let hj: string | undefined;
    let ga: string | undefined;
    let pd: string | undefined;
    let pdh: string | undefined;
    let pm: boolean | undefined;
    if (currentToken.value) {
      hj = currentToken.value.thj;
      ga = currentToken.value.tga;
      pd = currentToken.value.tpd;
      pdh = currentToken.value.tpdh;
      pm = currentToken.value.tpm;
    } else if (guestTokens.value) {
      for (const guestToken of Object.values(guestTokens.value)) {
        // Just assume there'll be at most one with values, even if there are multiple.
        if (guestToken.thj || guestToken.tga) {
          hj = guestToken.thj;
          ga = guestToken.tga;
          pd = guestToken.tpd;
          pdh = guestToken.tpdh;
          pm = guestToken.tpm;
          break;
        }
      }
    }
    const codes: TrackingCodes = {
      hotjar: hj,
      googleAnalytics: ga,
      pendo: pd,
      pendoHost: pdh,
      pendoMobile: pm,
    } as TrackingCodes;
    return codes;
  });

  const tenantDefaultViewChatAccess: ComputedRef<ChatAccessLevel> = computed(() => {
    if (!currentToken.value) {
      return ChatAccessLevel.ANYONE;
    }
    return currentToken.value.tenantDefaultViewChatAccess || ChatAccessLevel.ANYONE;
  });

  const tenantDefaultSendChatAccess: ComputedRef<ChatAccessLevel> = computed(() => {
    if (!currentToken.value) {
      return ChatAccessLevel.ANYONE;
    }
    return currentToken.value.tenantDefaultSendChatAccess || ChatAccessLevel.ANYONE;
  });

  const tenantAllowsChatSettingOverrides: ComputedRef<boolean> = computed(() => {
    if (!currentToken.value) {
      return false;
    }
    return !!currentToken.value.tenantAllowsChatSettingOverrides;
  });

  function setUserCredentialsGrants(grants: UserCredentialsGrant[]): void {
    Vue.set(userCredentialsGrants, 'value', grants);
  }

  function setCalendarMeetings(details: { calendarMeetings: ScheduledMeeting[] }): void {
    calendarMeetings.value = details.calendarMeetings;
  }

  function setFirstLoginProcessed(): void {
    localStorage.setItem(LOCAL_STORAGE_FIRST_LOGIN_PROCESSED_KEY, JSON.stringify(true));
  }

  function setHadExpiredTokenOnStartup(): void {
    hadExpiredTokenOnStartup.value = true;
  }

  function setCurrentToken(token: UserToken): void {
    currentToken.value = token;
    // Post the wiki id to the wiki data worker
    DataWorker.instance().dispatch('KnowledgeBase/setWikiId', token?.kbWikiId);
  }

  function resetCurrentToken(): void {
    currentToken.value = null;
  }

  function setCurrentUser(user: FullUserDetails): void {
    if (currentUser.value) {
      patchObject(currentUser.value as unknown as Record<string, unknown>, asRecord(user), true);
    } else {
      currentUser.value = user;
    }
  }

  function resetCurrentUser(): void {
    currentUser.value = null;
  }

  function setTenantUsers(details: {users: TenantUserDetails[], fullRefresh: boolean}): void {
    // If it's a full refresh, then delete any records that aren't in the updated object
    if (details.fullRefresh) {
      const removedTenantUsers: string[] = Object.keys(tenantUsers.value).filter((userId: string) => {
        return details.users.findIndex((user: TenantUserDetails) => user.id === userId) === -1;
      });
      for (const removedId of removedTenantUsers) {
        delete tenantUsers.value[removedId];
      }
    }
    for (const tenantUser of details.users) {
      setOrPatchObject(tenantUsers.value, tenantUser.id, asRecord(tenantUser));
    }
  }

  function setTenantUser(user: TenantUserDetails): void {
    setOrPatchObject(tenantUsers.value, user.id, asRecord(user));
  }

  function setUserContact(userContact: UserContact): void {
    if (!currentUser.value) {
      return;
    }
    if (currentUser.value.contacts) {
      const index: number = currentUser.value.contacts.findIndex(
        (current: UserContact) => current.id === userContact.id);
      if (index > -1) {
        patchContact(currentUser.value.contacts[index], userContact);
      } else {
        currentUser.value.contacts.push(userContact);
      }
    } else {
      Vue.set(currentUser.value, 'contacts', [userContact]);
    }
  }

  function removeContacts(ids: string[]): void {
    if (!currentUser.value) {
      return;
    }
    if (currentUser.value.contacts) {
      for (const id of ids) {
        const index: number = currentUser.value.contacts.findIndex(
          (current: UserContact) => current.id === id);
        if (index > -1) {
          currentUser.value.contacts[index].deleted = true;
        }
      }
    }
  }

  function setNewExternalUserList(details: {users: ExternalUserDetails[], fullRefresh: boolean}): void {
    // If it's a full refresh, then delete any records that aren't in the updated object
    if (details.fullRefresh) {
      const removedExternalUsers: string[] = Object.keys(externalTenantUsers.value).filter((userId: string) => {
        return details.users.findIndex((user: ExternalUserDetails) => user.id === userId) === -1;
      });
      for (const removedId of removedExternalUsers) {
        delete externalTenantUsers.value[removedId];
      }
    }
    for (const externalUser of details.users) {
      setOrPatchObject(externalTenantUsers.value, externalUser.id, asRecord(externalUser));
    }

    let permissionsUpdated = false;
    const usersByPermissionTypeToSet: ExternalTenantUsersByPermission = {};
    for (const externalUser of Object.values(externalTenantUsers.value)) {
      if (externalUser.allowedPermissionTypes && externalUser.allowedPermissionTypes.length) {
        for (const permissionType of externalUser.allowedPermissionTypes) {
          let newList: LimitedUserDetails[] = usersByPermissionTypeToSet[permissionType];
          if (!newList) {
            newList = [];
            Vue.set(usersByPermissionTypeToSet, permissionType, newList);
          }
          newList.push(externalUser);
        }
        permissionsUpdated = true;
      }
    }
    if (permissionsUpdated) {
      patchObject(externalTenantUsersByPermissionType.value, usersByPermissionTypeToSet);
    }
  }

  function setOnlineUserState(onlineUserStateToSet: OnlineUserState): void {
    if (onlineUserState.value) {
      patchObject(onlineUserState.value as unknown as Record<string, unknown>, asRecord(onlineUserStateToSet), true);
    } else {
      onlineUserState.value = onlineUserStateToSet;
    }
  }

  function addUserState(toAdd: UserState): void {
    if (!currentToken.value) {
      return;
    }
    let stateArr: UserState[] = currentToken.value.userState;
    if (!stateArr) {
      stateArr = [];
      Vue.set(currentToken.value, 'userState', stateArr);
    }
    if (!stateArr.find((current: UserState) => current.stateKey === toAdd.stateKey)) {
      stateArr.push(toAdd);
    }
  }

  function setWorkflowUserPromise(workflowUserPromiseToSet: Promise<LimitedUserDetails | null> | null): void {
    workflowUserPromise.value = workflowUserPromiseToSet;
  }

  function setTenantWorkflowUser(tenantWorkflowUserToSet: LimitedUserDetails | null): void {
    tenantWorkflowUser.value = tenantWorkflowUserToSet;
  }

  function setAnonymousUserPromise(anonymousUserPromiseToSet: Promise<LimitedUserDetails | null> | null): void {
    anonymousUserPromise.value = anonymousUserPromiseToSet;
  }

  function setTenantAnonymousUser(tenantAnonymousUserToSet: LimitedUserDetails | null): void {
    tenantAnonymousUser.value = tenantAnonymousUserToSet;
  }

  function clearNavBarItems(): void {
    navBarItems.value = [];
  }

  function setNavBarItem(navBarItem: NavBarItem): void {
    const index: number = navBarItems.value.findIndex((current) => current.id === navBarItem.id);
    if (index > -1) {
      Vue.set(navBarItems.value, index, navBarItem);
    } else {
      // new item
      navBarItems.value.push(navBarItem);
    }
  }

  function setNavBarItems(details: {navBarItems: NavBarItem[], fullRefresh: boolean}): void {
    if (details.fullRefresh) {
      clearNavBarItems();
    }
    details.navBarItems.forEach(updatedItem => {
      setNavBarItem(updatedItem);
    });
  }

  async function updateNavBarItem(navBarItem: NavBarItem): Promise<void> {
    const updatedItem = await DataWorker.instance().dispatch('NavBarItems/updateNavBarItem', navBarItem);
    setNavBarItem(updatedItem);
  }

  async function getAllKnownUserDetailsIncludingWorkflowUsers(): Promise<Record<string, LimitedUserDetails>> {
    const result: Record<string, LimitedUserDetails> = allKnownUserDetails.value;
    let workflowUser: LimitedUserDetails | null = tenantWorkflowUser.value;
    let anonymousUser: LimitedUserDetails | null = tenantAnonymousUser.value;
    try {
      if (!workflowUser) {
        if (!workflowUserPromise.value) {
          const workflowUserPromise: Promise<LimitedUserDetails | null> = new Promise((resolve, reject) => {
            DataWorker.instance().dispatch('TenantUsers/getTenantWorkflowUser').then(
              (workflowUser: LimitedUserDetails | null) => {
                if (workflowUser) {
                  setTenantWorkflowUser(workflowUser);
                } else {
                  // Allow another request after 10 minutes
                  setTimeout(() => {
                    setWorkflowUserPromise(null);
                  }, 600_000);
                }
                resolve(workflowUser);
              }).catch((error) => {
              reject(error);
            });
          });
          setWorkflowUserPromise(workflowUserPromise);
        }
        workflowUser = await workflowUserPromise.value;
      }
    } catch (error) {
      log.error('Error fetching user: ' + error);
    }
    try {
      if (!anonymousUser) {
        if (!anonymousUserPromise.value) {
          const anonymousUserPromise: Promise<LimitedUserDetails | null> = new Promise((resolve, reject) => {
            DataWorker.instance().dispatch('TenantUsers/getTenantAnonymousUser').then(
              (anonymousUser: LimitedUserDetails | null) => {
                if (anonymousUser) {
                  setTenantAnonymousUser(anonymousUser);
                } else {
                  // Allow another request after 10 minutes
                  setTimeout(() => {
                    setAnonymousUserPromise(null);
                  }, 600_000);
                }
                resolve(anonymousUser);
              }).catch((error) => {
              reject(error);
            });
          });
          setAnonymousUserPromise(anonymousUserPromise);
        }
        anonymousUser = await anonymousUserPromise.value;
      }
    } catch (error) {
      log.error('Error fetching user: ' + error);
    }

    if (workflowUser) {
      result[workflowUser.id] = workflowUser;
    }
    if (anonymousUser) {
      result[anonymousUser.id] = anonymousUser;
    }
    return result;
  }

  /**
   * IMPORTANT NOTE: This method does not call setLogoutMarkerInLocalStorage.
   * This is to avoid a loop of tabs triggering logout back and forth.
   * This means setLogoutMarkerInLocalStorage needs to be called directly by the method that calls clearUserState
   */
  async function clearUserState(): Promise<void> {
    resetCurrentToken();
    resetCurrentUser();
    removeUserTokenFromLocalStorage();
  }

  async function isUserAuthenticated(): Promise<boolean> {
    if (isCurrentTokenValid.value) {
      return true;
    }
    try {
      await authenticateUser();
    } catch (error) {
      log.debug(`Caught error authenticating user: ${error}`);
    }
    return isCurrentTokenValid.value;
  }

  function setUserTrackMapping(mapping: { userMapping: string; trackId: string }): void {
    const { userMapping, trackId } = mapping;
    Vue.set(userTrackMap.value, userMapping, trackId);
  }

  function setGuestNameOnServer(nameOnServer: string | null): void {
    guestNameOnServer.value = nameOnServer;
  }

  function setGuestDisplayName(name: string): void {
    guestDisplayName.value = name;
  }

  function addGuestToken(trackAndToken: { trackId: string; token: UserToken }): void {
    const { trackId, token } = trackAndToken;
    Vue.set(guestTokens.value, trackId, token);
    if (token.guestId) {
      const guestTokenLocalStorageKey = getGuestTokenLocalStorageKey(trackId);
      const existingGuestTokenId = localStorage.getItem(guestTokenLocalStorageKey);
      if (existingGuestTokenId !== token.guestId && token.appId) {
        // A different guest ID is now being used - remove any app auth that used the old guest ID
        // eslint-disable-next-line no-trailing-spaces
        removeAppAuthTokenFromLocalStorage(token.appId,
          getEnvironmentIdToUseForAppAuth(useTracksStore().tracks[trackId]), trackId);
      }
      localStorage.setItem(guestTokenLocalStorageKey, token.guestId);
    }
  }

  function deleteGuestToken(trackId: string): void {
    log.debug(`Deleting guest token for trackId: ${trackId}`);
    Vue.delete(guestTokens.value, trackId);
    localStorage.removeItem(getGuestTokenLocalStorageKey(trackId));
  }

  function addFailedUserRequest(userId: string): void {
    if (failedUserDetailsRequests.value[userId]) {
      failedUserDetailsRequests.value[userId] += 1;
    } else {
      failedUserDetailsRequests.value[userId] = 1;
    }
  }

  // TODO At the moment this takes an entryId (the active entry ID from App.vue) but for a guest user,
  //      that is always undefined at the moment. If the token expires after three hours, should we be
  //      checking if the user is in a meeting (and using the 'meeting' entryId like we do in the router),
  //      and/or getting the active entry ID (or presented entry ID) if available?
  //      Also, will this always be the current meeting track ID?
  async function updateGuestOnlineStatus(trackId: string): Promise<GuestOnlineStatusDetails | undefined> {
    const guestToken = guestTokens.value[trackId];
    if (!guestToken) {
      log.debug(`No guest token available for trackId ${trackId}; not updating guest online status`);
      // TODO Update this to a null/offline status?
      return;
    }

    // If we're a logged in user, but still a guest, then use the user's display name
    let guestName;
    if (currentUser.value) {
      guestName = currentUser.value.displayName;
    } else {
      guestName = guestDisplayName.value ?? '';
    }
    const guestId = guestToken.guestId;

    // If the guest name hasn't changed from what the server thought it was on the last update, just send null. If we
    // don't do this, the server is doing a load of encrypting/decrypting which is doesn't need to do
    guestName = guestName !== guestNameOnServer.value ? guestName : null;
    try {
      const guestOnlineStatus: TrackOnlineStatus =
        await DataWorker.instance()
          .dispatch('OnlineStatus/updateGuestOnlineStatus', trackId, guestId, guestName);

      // Keep track of the guest name we get back (so we can send null if we need to as above)
      const nameFromServer = guestOnlineStatus.online.find(status => status.guestId === guestId)?.name ?? null;
      setGuestNameOnServer(nameFromServer);

      // The online statuses for a guest are kept separately in the online status store
      const trackOnlineStatusesStore = useTrackOnlineStatusesStore(pinia);
      trackOnlineStatusesStore.setGuestOnlineStatus({ trackId, onlineStatus: guestOnlineStatus });

      if (guestId) {
        const tracksStore = useTracksStore();
        await tracksStore.updateGuestTracks(guestId);
      }
    } catch (err) {
      // TODO Should we get a new token here?
      log.error(`Error updating guestOnlineStatus: ${err}`);
    }
    return {
      trackId: trackId,
      guestId: guestId,
      name: guestName || undefined,
    };
  }

  async function getGuestToken(tokenRequest: GuestTokenRequest): Promise<UserToken> {
    let trackId;
    switch (tokenRequest.type) {
      case GuestTokenRequestType.USER_TRACK:
        trackId = userTrackMapping.value(tokenRequest.domain, tokenRequest.user);
        break;
      default:
        trackId = tokenRequest.trackId;
        break;
    }

    let token: UserToken;
    if (trackId) {
      // See if there's an existing guest token for this track, but ignore it if the request explicitly wants a fresh
      // token
      // TODO: if there is ever a point where a guest is joining a meeting while viewing an app, we will need to add
      // the meeting info to the app token (or vice versa)
      token = guestTokens.value[trackId];
      if (token && isTokenValid(token) && tokenRequest.ignoreCachedToken !== true) {
        return token;
      }
    }

    try {
      // TODO: Do this in the shared worker
      if (trackId) {
        const trackOnlineStatusesStore = useTrackOnlineStatusesStore(pinia);
        trackOnlineStatusesStore.clearGuestOnlineStatuses(trackId);
      }

      const lastGuestId = localStorage.getItem(getGuestTokenLocalStorageKey(trackId || ''));
      if (lastGuestId) {
        tokenRequest.lastGuestId = lastGuestId;
      }

      const response: GuestTokenResponse = await DataWorker.instance().dispatch('User/getGuestToken', tokenRequest);
      token = response.token;
      await DataWorker.instance().handleGuestAuth(response.dataSyncRequired);
    } catch (err) {
      throw new Error(`Failed to get guest token for track: ${trackId} \nError: ${err}`);
    }

    if (token) {
      const trackIdToStore = trackId ?? token.tid;
      if (!trackIdToStore) {
        throw new Error('Failed to get guest token; no track ID provided');
      }

      // If this was a token for a user track, cache the user => trackId mapping
      if (tokenRequest.type === GuestTokenRequestType.USER_TRACK) {
        const userMapping = `${tokenRequest.domain}/${tokenRequest.user}`;
        setUserTrackMapping({ userMapping, trackId: trackIdToStore });
      }

      addGuestToken({ trackId: trackIdToStore, token });
    }
    return token;

    // Possibly check guest state here for first use, etc?
    // Possible set initialised state?
  }

  async function authenticateUser(): Promise<UserToken> {
    try {
      let token: UserToken | null = currentTokenIfValid.value;
      if (!token) {
        try {
          token = getUserTokenFromLocalStorage(false);
          // We got *A* token
          if (token) {
            if (!isTokenValid(token)) {
              // It isn't valid, probably expired, so let's make a note of that and attempt a silent refresh later if we
              // go to a meeting
              setHadExpiredTokenOnStartup();
              token = null;
            } else {
              setCurrentToken(token);
            }
          }
        } catch (error) {
          throw new Error(`Error during getUserTokenFromLocalStorage: ${error}`);
        }
      }

      if (token && token?.cvs !== UI_VERSION) {
        // If the existing token happened from a different major version to the current code (typically after the
        // service worker has just been updated and the UI force-reloaded), then request a new one in case the
        // server needs to change some data. As well as getting a new token, this also implicitly causes the
        // server to set an updated 'client version' cookie which can be used to affect the behaviour of various
        // requests on the server.
        token = null;
      }

      if (!token) {
        try {
          perfLog.debug(perfMsg('Awaiting new user token from the data worker', true));
          token = await DataWorker.instance().dispatch('User/getUserToken');
          perfLog.debug(perfMsg('Received new user token from the data worker', true));
          if (!token) {
            throw new Error('Failed to get user token');
          }
          setCurrentToken(token);
          setUserTokenInLocalStorage(token);
          removeLogoutMarkerFromLocalStorage();
          // TODO: The old UI looks to do it here, but I don't know if it makes sense
          PushMessaging.register();
        } catch (error) {
          resetCurrentUser();
          throw new Error(`Unable to get user token from server: ${error}`);
        }
        if (!token) {
          throw new Error('Failed to get user token');
        }
      }

      // Update the user, and update guest tokens
      await updateCurrentUserFromToken(token);

      // make sure we have a user content token
      DataWorker.instance().dispatch('User/refreshUserContentToken');

      return token;
    } catch (error) {
      log.debug(`Unhandled error authenticating user: ${error}`);
      throw new Error(`Unable to authenticate user: ${error}`);
    }
  }

  async function updateCurrentUserFromToken(token: UserToken): Promise<void> {
    if (!token) {
      log.error('Tried to update user from token, but no token was provided');
    }
    if (!currentUser.value) {
      // Update it immediately, then replace ASAP with value fetched from server
      const immediateUserDetails: FullUserDetails = {
        id: token.userId,
        email: token.email,
        displayName: token.name,
      };
      setCurrentUser(immediateUserDetails);
    }

    // Start updating the login details in the worker.
    const workerLoginPromise: Promise<void> = new Promise(() => {
      DataWorker.waitForInstance().then((worker: DataWorker) => {
        worker.handleLogin(token.userId, token.tenantId, token.ufg).then((userDetails: FullUserDetails) => {
          setCurrentUser(userDetails);
          updateGuestTokens();
        });
      });
    });

    if (initialUserInStorage && initialUserInStorage.userId !== token.userId) {
      log.info(perfMsg('User has changed. Waiting on shared worker to prepare data'));
      await workerLoginPromise;
      // Null this off because we know it'll be different from now on, but we've already handled it
      initialUserInStorage = null;
    }
  }

  async function refreshAllUsers(): Promise<void> {
    await DataWorker.instance().dispatch('TenantUsers/refreshTenantUsers');
    await DataWorker.instance().dispatch('ExternalUsers/refreshExternalUsers');
  }

  async function setAutoOpenLinks(autoOpen: boolean): Promise<void> {
    if (currentUser.value) {
      try {
        currentUser.value.openLinks = autoOpen;
        const updatedUser: FullUserDetails =
          await DataWorker.instance().dispatch('User/updateCurrentUser', currentUser.value);
        setCurrentUser(updatedUser);
      } catch (error) {
        log.error('Error updating current user: ' + error);
      }
    }
  }

  async function updateCurrentUser(userDetails: FullUserDetails): Promise<FullUserDetails | undefined> {
    try {
      const updatedUser: FullUserDetails =
        await DataWorker.instance().dispatch('User/updateCurrentUser', userDetails);
      setCurrentUser(updatedUser);
      return updatedUser;
    } catch (error) {
      log.error('Error updating current user: ' + error);
    }
  }

  async function updateUserStatus(details: {userId: string, status: UserStatus}): Promise<FullUserDetails | undefined> {
    try {
      const updatedUser: FullUserDetails =
        await DataWorker.instance().dispatch('User/updateUserStatus', details.userId, details.status);
      setCurrentUser(updatedUser);
      return updatedUser;
    } catch (error) {
      log.error('Error updating user status: ' + error);
    }
  }

  async function clearUserStatus(userId: string): Promise<FullUserDetails | undefined> {
    try {
      const updatedUser: FullUserDetails =
        await DataWorker.instance().dispatch('User/clearUserStatus', userId);
      setCurrentUser(updatedUser);
      return updatedUser;
    } catch (error) {
      log.error('Error clearing user status: ' + error);
    }
  }

  async function updateCurrentUserDoNotDisturb(doNotDisturbUserRequest: DoNotDisturbUserRequest):
    Promise<FullUserDetails | undefined> {
    try {
      const updatedUser: FullUserDetails =
        await DataWorker.instance().dispatch('User/updateCurrentUserDoNotDisturb', doNotDisturbUserRequest);
      setCurrentUser(updatedUser);
      return updatedUser;
    } catch (error) {
      log.error('Error updating current do not disturb user: ' + error);
    }
  }

  async function uploadUserAvatar(file: File): Promise<string | undefined> {
    try {
      return await DataWorker.instance().dispatch('User/uploadUserAvatar', file);
    } catch (error) {
      log.error('Error uploading user avatar: ' + error);
    }
  }

  async function updateTenantUser(userDetails: TenantUserDetails): Promise<TenantUserDetails | undefined> {
    try {
      const updatedUser: TenantUserDetails =
        await DataWorker.instance().dispatch('TenantUsers/updateTenantUser',
          userDetails);
      setTenantUser(updatedUser);
      return updatedUser;
    } catch (error) {
      log.error('Error updating user: ' + error);
    }
  }

  async function addCalendarContact(details: { calendarContactId: string, visible: boolean }): Promise<void> {
    if (!currentUser.value || !details?.calendarContactId) {
      return;
    }
    // Ask server to create the new calendar contact
    try {
      await DataWorker.instance().dispatch('User/addCalendarContact', currentUser.value.id, details.calendarContactId,
        details.visible);
    } catch (error) {
      log.error(error);
    }

    // And now asks the UserWorkerModule to update the local DB and propagate the change...
    await DataWorker.instance().dispatch('User/refreshCurrentUser');
  }

  async function updateCalendarContact(calendarContact: UserCalendarContact): Promise<void> {
    if (!currentUser.value || !calendarContact.id) {
      return;
    }

    // First update the local representation. Then update the server.
    // This allows the UI to react promptly when the user quickly
    // enable/disable a contact visibility...

    const calendarContactPreferences: UserCalendarContactDto[] =
      Object.assign([], currentUser.value.calendarContactPreferences);
    const updatedContact = calendarContactPreferences.find(element => element.id === calendarContact.id);
    if (!updatedContact) {
      // Just a failsafe... Calendar contact must have been removed on the server side.
      // This has overriding priority and the view will update shortly if it has not already...
      return;
    }
    // Not adding new properties => normal Javascript assign won't break reactivity.
    // Keep the contactId used by the DTO, but remove the live contact ref (not used by DTO).
    Object.assign(updatedContact,
      calendarContact,
      { contact: undefined },
      { contactId: calendarContact.contact.id });
    const updatedDetails: FullUserDetails = Object.assign({}, currentUser.value);
    updatedDetails.calendarContactPreferences = calendarContactPreferences;

    // Update the local state (so that user selection is immediately visible)
    // (but do not dispatch to 'updateCurrentUser' as this would trigger a full push
    // of the user profile to the server)
    setCurrentUser(updatedDetails);

    // Then pass the update to the server side too...
    try {
      const userId = currentUser.value.id;
      await DataWorker.instance().dispatch('User/updateCalendarContactVisibility', userId, updatedContact);
    } catch (error) {
      log.error(error);
    }

    // And now asks the UserWorkerModule to update the local DB and propagate the change...
    await DataWorker.instance().dispatch('User/refreshCurrentUser');
  }

  async function removeCalendarContact(calendarContactId: string): Promise<void> {
    if (!currentUser.value || !calendarContactId) {
      return;
    }

    // Tell the server side to remove the contact
    try {
      await DataWorker.instance().dispatch('User/removeCalendarContact', currentUser.value.id, calendarContactId);
    } catch (error) {
      log.error(error);
    }

    // And now asks the UserWorkerModule to update the local DB and propagate the change...
    await DataWorker.instance().dispatch('User/refreshCurrentUser');
  }

  async function fetchUserDetails(userId: string): Promise<LimitedUserDetails | undefined> {
    // This method doesn't add the user to the indexedDB. It should be used as a last resort.
    // See get displayDetailsForUsers instead
    try {
      let details: LimitedUserDetails | undefined = miscellaneousUserDetails.value[userId];
      if (!details) {
        const failedAttempts: number = failedUserDetailsRequests.value[userId] || 0;
        if (failedAttempts < MAX_USER_DETAILS_ATTEMPTS) {
          details = await DataWorker.instance().dispatch('User/fetchUserDetails', userId);
          if (details) {
            Vue.set(miscellaneousUserDetails.value, details.id, details);
          }
        }
      }
      return details;
    } catch (error) {
      log.error('Error fetching user: ' + error);
      addFailedUserRequest(userId);
    }
  }

  async function getUserDetailsAsGuest(payload: GetUserDetailsAsGuestPayload): Promise<LimitedUserDetails | undefined> {
    try {
      let details: LimitedUserDetails | undefined = miscellaneousUserDetails.value[payload.userId];
      if (!details) {
        const failedAttempts: number = failedUserDetailsRequests.value[payload.userId] || 0;
        if (failedAttempts < MAX_USER_DETAILS_ATTEMPTS) {
          details = await DataWorker.instance().dispatch('User/getUserDetailsAsGuest', payload);
          if (details) {
            Vue.set(miscellaneousUserDetails.value, details.id, details);
          }
        }
      }
      return details;
    } catch (error) {
      log.error(`Error fetching User details: ${error}`);
      addFailedUserRequest(payload.userId);
    }
  }

  async function addNewUserState(state: UserState): Promise<void> {
    if (!currentUser.value || !currentToken.value) {
      return;
    }
    try {
      const addedState: UserState = await DataWorker.instance().dispatch('User/addUserState',
        currentUser.value.id, state);
      addUserState(addedState);
      setUserTokenInLocalStorage(currentToken.value);
    } catch (error) {
      log.error(error);
    }
  }

  async function updateCurrentToken(): Promise<void> {
    const newToken: UserToken = await DataWorker.instance().dispatch('User/getUserToken');
    try {
      setCurrentToken(newToken);

      setUserTokenInLocalStorage(newToken);
      removeLogoutMarkerFromLocalStorage();

      // This posts to the shared worker to get all other instances to refresh the current token
      // from local storage
      await DataWorker.instance().dispatch('User/reloadUserTokenFromStorage');

      if (!currentUser.value) {
        // Probably a silent sign on, so there are no existing user details. We need to update them.
        await updateCurrentUserFromToken(newToken);
      }
    } catch (error) {
      resetCurrentUser();
      throw new Error(`Unable to get user token from server: ${error}`);
    }
  }

  /**
   * If we have a new auth token as a logged in user, we need to update our guest tokens, in case we're are now _not_ a
   * guest of one of the guest tracks.
   *
   * e.g. We go to premeet as a guest, silently sign on, and become a logged in user. We have a guest token for the
   *      meeting track (which would have persisted if the silent sign in failed), but now that we are authenticated,
   *      we can check if we're a member of the meeting track. If we are, then we shouldn't be doing guest updates
   *      anymore, so we can delete our guest token.
   */
  async function updateGuestTokens(): Promise<void> {
    // If we don't have any guest tokens, then there's nothing to do
    if (!guestTokens.value || !Object.values(guestTokens.value).length) {
      return;
    }

    const meetingTrackId = meetingStore.meetingTrackId;
    try {
      await waitForTrackListUpdate();
      const tracksStore = useTracksStore();
      const allTracks: Track[] = tracksStore.allTracks;
      if (meetingTrackId && allTracks.map(track => track.id).includes(meetingTrackId)) {
        deleteGuestToken(meetingTrackId);
      }
    } catch (err) {
      log.error(`Error waiting for track list to update, err: ${JSON.stringify(err)}`);
      log.error(err);
    }
  }

  async function refreshCurrentTokenFromStorage(): Promise<void> {
    let token: UserToken | null = null;
    try {
      token = getUserTokenFromLocalStorage();
      if (token) {
        setCurrentToken(token);
        if (!currentUser.value) {
          await updateCurrentUserFromToken(token);
        }
      }
    } catch (error) {
      throw new Error(`Error during getUserTokenFromLocalStorage: ${error}`);
    }
  }

  async function refreshCalendar(): Promise<void> {
    if (!currentUser.value || !currentToken.value) {
      return;
    }
    try {
      await DataWorker.instance().dispatch('User/refreshCalendarData');
    } catch (error) {
      log.error(error);
    }
  }

  async function createContact(contact: UserContact): Promise<UserContact | undefined> {
    if (!currentUser.value || !currentToken.value) {
      return;
    }
    try {
      const contactDetails: UserContact = await DataWorker.instance()
        .dispatch('User/createContact', contact);
      await DataWorker.instance().dispatch('User/refreshUserContacts');
      return contactDetails;
    } catch (error) {
      log.error(error);
    }
  }

  async function updateContact(contact: UserContact): Promise<UserContact | undefined> {
    if (!currentUser.value || !currentToken.value) {
      return;
    }
    try {
      const contactDetails: UserContact = await DataWorker.instance()
        .dispatch('User/updateContact', contact);
      setUserContact(contactDetails);
      return contactDetails;
    } catch (error) {
      log.error(error);
    }
  }

  async function deleteContacts(ids: string[]): Promise<void> {
    if (!currentUser.value || !currentToken.value) {
      return;
    }
    try {
      await DataWorker.instance().dispatch('User/deleteContacts', ids);
      removeContacts(ids);
    } catch (error) {
      log.error(error);
    }
  }

  async function getAppBuilderUserIds(): Promise<string[] | undefined> {
    try {
      return await DataWorker.instance().dispatch('User/getAppBuilderUserIds');
    } catch (error) {
      log.error(error);
    }
  }

  async function getCredentialsGrants(): Promise<UserCredentialsGrant[] | undefined> {
    try {
      const grants = await DataWorker.instance().dispatch('User/getCredentialsGrants');
      setUserCredentialsGrants(grants);
      return grants;
    } catch (error) {
      log.error(error);
    }
  }

  async function addUserCredentialsGrant(request: GrantUserCredentialsRequest):
    Promise<UserCredentialsGrant | undefined> {
    try {
      return await DataWorker.instance().dispatch('User/addUserCredentialsGrant', request);
    } catch (error) {
      log.error(error);
    }
  }

  async function disableUserCredentialsGrant(grantId: string):
    Promise<UserCredentialsGrant | undefined> {
    try {
      return await DataWorker.instance().dispatch('User/disableUserCredentialsGrant', grantId);
    } catch (error) {
      log.error(error);
    }
  }

  async function enableUserCredentialsGrant(grantId: string):
    Promise<UserCredentialsGrant | undefined> {
    try {
      return await DataWorker.instance().dispatch('User/enableUserCredentialsGrant', grantId);
    } catch (error) {
      log.error(error);
    }
  }

  async function revokeUserCredentialsGrant(grantId: string):
    Promise<UserCredentialsGrant | undefined> {
    try {
      return await DataWorker.instance().dispatch('User/revokeUserCredentialsGrant', grantId);
    } catch (error) {
      log.error(error);
    }
  }

  function userDetailsRecordForMembers(trackMembers: Member[], currentUser: FullUserDetails | null,
    tenantUsers: TenantUserDetails[], externalTenantUsers: ExternalUserDetails[]): Record<string, LimitedUserDetails> {
    const userDetails: Record<string, LimitedUserDetails> = {};
    for (const member of trackMembers) {
      if (currentUser && member.userId === currentUser.id) {
        userDetails[currentUser.id] = currentUser;
      } else {
        let user: LimitedUserDetails | undefined = tenantUsers.find((current: LimitedUserDetails) =>
          current.id === member.userId);
        if (!user) {
          user = externalTenantUsers.find((current: LimitedUserDetails) =>
            current.id === member.userId);
        }
        if (user) {
          userDetails[user.id] = user;
        } else {
          userDetails[member.userId] = {
            id: member.userId,
            email: member.email ?? '',
            displayName: member.email ?? 'Anonymous user'
          };
        }
      }
    }
    return userDetails;
  }

  function patchContact(target: UserContact, source: UserContact, removeMissingProps?: boolean): void {
    patchObject(asRecord(target), asRecord(source), removeMissingProps);
  }

  function getGuestTokenLocalStorageKey(trackId: string): string {
    return GUEST_TOKEN_LOCAL_STORAGE_PREFIX + trackId;
  }

  return {
    currentToken,
    currentUser,
    tenantUsers,
    externalTenantUsersByPermissionType,
    navBarItems,
    hadExpiredTokenOnStartup,
    calendarMeetings,
    guestDisplayName,
    guestNameOnServer,
    isUserTenantAdmin,
    isUserSystemAdmin,
    isReportingUser,
    allUsers,
    allTenantActiveUsers,
    allTenantUsers,
    allExternalTenantUsers,
    allExternalTenantUsersByPermissionType,
    confirmedTenantUsers,
    confirmedTenantUsersAsContacts,
    allExternalTenantUsersAsContacts,
    contacts,
    calendarContacts,
    currentTokenIfValid,
    currentTokenARID,
    tenantId,
    meetingUrl,
    currentUserId,
    currentUserEmail,
    currentUserDetails,
    currentUserStatus,
    userDisplayName,
    userAvatar,
    userColour,
    displayDetailsForUsers,
    userStatusesForUsers,
    allKnownUserDetails,
    displayDetailsForUsersByEmail,
    isGuestMember,
    guestIdForTrack,
    guestIdForCurrentMeeting,
    guestTokenForCurrentMeeting,
    guestName,
    guestTokenForTrack,
    isGuestUser,
    userDetailsForActiveTrackMembers,
    userDetailsForTrackMembers,
    userDetailsRecordForMeetingTrackMembers,
    userDetailsRecordForActiveTrackMembers,
    userDetailsRecordForTrackMembers,
    confirmedUserDetailsForActiveTrackMembers,
    userDetailsForChatTracks,
    currentUserActiveTrackMembership,
    currentUserChatTrackMemberships,
    trackMembership,
    isCurrentUserConfirmedMemberOfActiveTrack,
    autoOpenLinks,
    onlineUserIds,
    userState,
    selectedUserDetails,
    canConfigureApp,
    canConfigureApps,
    isUserFeatureEnabled,
    isGuestOfActiveTrack,
    currentUserCalendarMeetings,
    currentUserCalendar,
    guestMemberId,
    isMeetingGuestOrGuestMember,
    isThirdPartyMeetingGuestOrGuestMember,
    appIntegrations,
    isFirstLogin,
    trackingCodes,
    tenantDefaultViewChatAccess,
    tenantDefaultSendChatAccess,
    tenantAllowsChatSettingOverrides,
    userCredentialsGrants,
    setCalendarMeetings,
    setFirstLoginProcessed,
    setCurrentUser,
    setTenantUsers,
    setNewExternalUserList,
    setOnlineUserState,
    addUserState,
    setNavBarItems,
    updateNavBarItem,
    getAllKnownUserDetailsIncludingWorkflowUsers,
    clearUserState,
    isUserAuthenticated,
    setGuestDisplayName,
    updateGuestOnlineStatus,
    getGuestToken,
    refreshAllUsers,
    setAutoOpenLinks,
    updateCurrentUser,
    updateUserStatus,
    clearUserStatus,
    updateCurrentUserDoNotDisturb,
    uploadUserAvatar,
    updateTenantUser,
    addCalendarContact,
    updateCalendarContact,
    removeCalendarContact,
    fetchUserDetails,
    getUserDetailsAsGuest,
    addNewUserState,
    updateCurrentToken,
    updateGuestTokens,
    refreshCurrentTokenFromStorage,
    refreshCalendar,
    createContact,
    updateContact,
    deleteContacts,
    getAppBuilderUserIds,
    getCredentialsGrants,
    addUserCredentialsGrant,
    disableUserCredentialsGrant,
    enableUserCredentialsGrant,
    revokeUserCredentialsGrant,
  };
});
