import { RouteLocationRaw } from 'vue-router';

import { LimitedUserDetails } from './UserDetails';

export enum NotificationType {
  CHAT = 'CHAT',
  GROUPCHAT = 'GROUPCHAT',
  WORKSPACE = 'WORKSPACE',
  ARTICLE = 'ARTICLE',
  ARTICLE_COMMENT = 'ARTICLE_COMMENT',
}

export interface Notification {
  title: string;
  subtext: string | null;
  icon: string;
  canDelete: boolean;
  timestamp: Date;
  type: NotificationType;
  route: RouteLocationRaw;
  userIdent: LimitedUserDetails | null;
  displayNumber: number | null;
  deleteHandler?: () => void;
}
