import { Dictionary } from 'lodash';
import { RouteLocationRaw } from 'vue-router';

import { AppName } from '@/data/Branding';
import { Notification, NotificationType } from '@/data/datatypes/Notification';
import { ActivityType, Track, TrackActivity, TrackType } from '@/data/datatypes/Track';
import { FullUserDetails, LimitedUserDetails } from '@/data/datatypes/UserDetails';
import {
  getChatName, getOtherParticipantIds,
  getPrivateChatUsers
} from '@/data/helpers/ChatHelper';
import { DEFAULT_APP_ICON } from '@/data/helpers/TaskHelper';
import {
  getFormattedLastChatMessage, getFormattedThreadChatMessage
} from '@/data/helpers/TrackHelper';
import { sortByDisplayName } from '@/data/helpers/UserHelper';
import { MiniApp } from '@/data/tasks/MiniApp';
import { MiniAppView } from '@/data/tasks/TaskView';
import RouteNames from '@/router/RouteNames';
import { DEFAULT_COLLABORATION_APP_ID } from '@/stores/Tasks';
import { getRouteForTrack } from '@/stores/UIState';

import { NotificationBuilder } from './NotificationBuilder';

export default class TrackActivityNotificationBuilder implements NotificationBuilder {
  private currentUser!: FullUserDetails;
  private displayDetailsForUsers!: Map<string, LimitedUserDetails>;
  private activity!: TrackActivity;
  private app?: MiniApp;
  private view?: MiniAppView;
  private similarActivity?: TrackActivity[];

  constructor(currentUser: FullUserDetails,
    displayDetailsForUsers: Map<string, LimitedUserDetails>,
    activity: TrackActivity, app?: MiniApp, view?: MiniAppView, similarActivity?: TrackActivity[]) {
    this.currentUser = currentUser;
    this.displayDetailsForUsers = displayDetailsForUsers;
    this.activity = activity;
    this.app = app;
    this.view = view;
    this.similarActivity = similarActivity;
  }

  public build(): Notification | undefined {
    const notification = {
      title: this.notificationTitle,
      subtext: this.notificationSubtext,
      icon: this.notificationIcon,
      canDelete: this.activity.canDelete,
      timestamp: new Date(this.activity.date),
      userIdent: this.user,
      type: this.notificationType,
      route: this.activityRoute,
      displayNumber: this.displayNumber,
    };

    return this.isValid ? notification : undefined;
  }

  private get isValid():boolean {
    if (this.activity.type === ActivityType.NON_TASK_RECORD_MODIFIED && this.activity.tableId) {
      return !!this.view;
    }
    return true;
  }

  private get notificationIcon(): string {
    if (this.isChatActivity || this.isThreadChatActivity || this.track?.type === TrackType.PRIVATE_CHAT) {
      return 'light/chat-light';
    }
    if (this.isMeetingActivity) {
      return 'light/calendar-light';
    }
    if (this.isMiniAppRecordModificationActivity) {
      return this.app?.appIcon || DEFAULT_APP_ICON;
    }
    if (this.activity.type === ActivityType.TASK_MODIFIED) {
      return 'light/tasks-light';
    }
    return 'light/files-light';
  }

  private get notificationTitle(): string {
    // Indicates a system message...
    if (this.userName === AppName) {
      return AppName;
    }

    if (this.activity.type === ActivityType.THREAD_CHAT_MESSAGE) {
      if (this.track?.type === TrackType.PRIVATE_CHAT) {
        return 'Thread in ' + this.chatHeading;
      }
      return 'Thread in ' + this.track?.title;
    }

    if ((this.activity.type === ActivityType.CHAT_MESSAGE ||
      this.track?.type === TrackType.PRIVATE_CHAT)) {
      return this.chatHeading;
    }

    return this.track?.title ?? '';
  }

  private get notificationSubtext(): string | null {
    if (this.isThreadChatActivity && this.track) {
      return getFormattedThreadChatMessage(this.activity, this.displayDetailsForUsers);
    }

    if (this.isChatActivity && this.track) {
      if (this.track.type === TrackType.PRIVATE_CHAT) {
        // This is a private chat, so the sender's name will be on top (see notificationTitle)
        // Meaning we should not put the name again at the bottom unless it is a group chat.
        if (this.groupChat) {
          return getFormattedLastChatMessage(this.track, this.displayDetailsForUsers);
        } else {
          if (this.track.lastChatMessage?.chatMessageContent.plain) {
            return this.track.lastChatMessage.chatMessageContent.plain;
          } else {
            return '';
          }
        }
      } else {
        // It is a chat activity but not a private chat. This means the track name will be displayed on top
        // So we must always have the user message prefixed with the username.
        return getFormattedLastChatMessage(this.track, this.displayDetailsForUsers);
      }
    }

    if (this.track?.type === TrackType.PRIVATE_CHAT) {
      // Ok, this is not a direct chat activity (as previous if block did not intercept)
      // But still a private chat. So will typically be a file attached to the chat (or similar).
      if (this.groupChat) {
        return this.userName + ' ' + (this.activity?.message ?? '');
      } else {
        return (this.activity?.message ?? '');
      }
    }

    return this.userName + ' ' + (this.activity?.message ?? '');
  }

  private get user(): LimitedUserDetails | null {
    if (!this.activity) {
      return null;
    }

    return this.activity.userId ? this.displayDetailsForUsers.get(this.activity.userId) ?? null : null;
  }

  private get userName(): string | null {
    return this.user?.displayName ?? this.activity?.userName ?? AppName;
  }

  private get isChatActivity(): boolean {
    return this.activity.type === ActivityType.CHAT_MESSAGE;
  }

  private get isThreadChatActivity(): boolean {
    return this.activity.type === ActivityType.THREAD_CHAT_MESSAGE;
  }

  private get isMeetingActivity(): boolean {
    // TODO : Need to work out how to specify that the activity is a request to a meeting
    return this.track?.type === TrackType.SCHEDULED_MEETING || this.activity.type === ActivityType.MEETING ||
    this.activity.type === ActivityType.MEETING_COMMENT || this.activity.type === ActivityType.SCHEDULE_MEETING;
  }

  private get isMiniAppRecordModificationActivity(): boolean {
    return this.activity.type === ActivityType.NON_TASK_RECORD_MODIFIED;
  }

  private get notificationType(): NotificationType {
    switch (this.activity.type) {
      case ActivityType.CHAT_MESSAGE:
      case ActivityType.THREAD_CHAT_MESSAGE:
        if (this.groupChat) {
          return NotificationType.GROUPCHAT;
        } else {
          return NotificationType.CHAT;
        }
      default:
        return NotificationType.WORKSPACE;
    }
  }

  private get activityRoute(): RouteLocationRaw {
    let name: string;
    if (!this.track) {
      name = RouteNames.HOME_ROUTE_NAME;
      return { name };
    }

    const tenantAppId: string = this.track?.owningMiniAppId || DEFAULT_COLLABORATION_APP_ID;
    const params: Dictionary<string> = {};
    const query: Dictionary<string> = {};

    switch (this.track?.type) {
      case TrackType.PRIVATE_CHAT:
        if (this.isThreadChatActivity && this.activity?.targetId) {
          name = RouteNames.PRIVATE_CHAT_SINGLE_THREAD_ROUTE_NAME;
          params.parentChatId = this.activity?.targetId;
        } else {
          name = RouteNames.PRIVATE_CHAT_ROUTE_NAME;
        }
        break;
      default:
        params.tenantAppId = tenantAppId;
        if (this.isChatActivity) {
          if (this.track?.lastChatMessage?.parentMessageId) {
            name = RouteNames.SINGLE_CHAT_THREAD_ROUTE_NAME;
            break;
          } else {
            name = RouteNames.TRACK_CHAT_ROUTE_NAME;
          }
        } else if (this.isThreadChatActivity && this.activity?.targetId) {
          name = RouteNames.SINGLE_CHAT_THREAD_ROUTE_NAME;
          params.parentChatId = this.activity?.targetId;
          break;
        } else if (this.activity.type === ActivityType.MEETING_COMMENT) {
          // Navigate to the chat thread for the entry
          return {
            name: RouteNames.SINGLE_CHAT_THREAD_ROUTE_NAME,
            params: {
              tenantAppId,
              trackId: this.track.id,
              parentChatId: this.activity?.entryId,
            } as Dictionary<string>,
          };
        } else if (this.isMeetingActivity) {
          name = RouteNames.TRACK_MEETINGS_ROUTE_NAME;
        } else if ((this.isMiniAppRecordModificationActivity ||
                    this.activity.type === ActivityType.TASK_MODIFIED) && this.activity?.targetId) {
          if (this.view && this.app) {
            let hashValue: string = `#${this.activity.targetId}`;
            if (this.similarActivity && this.similarActivity.length) {
              hashValue += ';' + this.similarActivity.map(activity => activity.targetId).join(';');
            }
            return {
              name: RouteNames.TRACK_APP_VIEW_ROUTE_NAME,
              params: {
                tenantAppId,
                trackId: this.track.id,
                appId: this.app?.id,
                viewId: this.view.id
              },
              hash: hashValue
            };
          } else {
            return {
              name: RouteNames.SINGLE_TRACK_TASK_ROUTE_NAME,
              params: {
                tenantAppId,
                trackId: this.track.id,
                taskId: this.activity.targetId
              } as Dictionary<string>,
            };
          }
        } else if (this.activity?.entryId) {
          name = RouteNames.TRACK_ENTRY_ROUTE_NAME;
        } else {
          return getRouteForTrack(this.track.id, tenantAppId);
        }
        break;
    }

    params.trackId = this.track?.id;
    if (this.activity?.entryId) {
      params.entryId = this.activity.entryId;
    }

    if (this.activity?.type) {
      // Set the desired view (i.e. display the comments for a comments notification) in the params.
      // Setting it in the query would make the URL copy/pastable but currently UI changes (toggling
      // the side panel) would not be reflected (as current code does not use the route to control this).
      // So, to avoid conflicts, the type of view is stored only as a dynamic parameter.
      params.viewMode = this.activity.type;
    }

    // Add the dummy query param to the notification link so that a route change is
    // force detected. This helps when there is conflict between route (param) states
    // and the conflicting Pinia Store representation (an example of this is the comment panel).
    query.ts = '' + new Date().getTime();

    return { name, params, query };
  }

  private get otherUserDetails(): LimitedUserDetails[] {
    if (this.track) {
      const otherIds: string[] = getOtherParticipantIds(this.track, this.currentUser.id);
      return otherIds.map((userId) => this.displayDetailsForUsers.get(userId))
        .filter((userDetails) => !!userDetails)
        // Reverse the order as we render in reverse order to achieve the overlap
        // The filter will remove undefined values, but TS doesn't recognise that, so explicitly cast it
        .sort(sortByDisplayName).reverse() as LimitedUserDetails[];
    }
    return [];
  }

  private get groupChat(): boolean {
    return (this.otherUserDetails?.length ?? 0) > 1;
  }

  private get chatHeading(): string {
    if (this.track) {
      return getChatName(this.track, this.currentUser.id, this.displayDetailsForUsers);
    }
    return '';
  }

  private get displayNumber(): number | null {
    let num: number | null = null;
    if (this.groupChat) {
      num = this.privateChatUsers?.length ?? null;
    }
    return num;
  }

  private get privateChatUsers(): LimitedUserDetails[] | null {
    let privateChatUsers: LimitedUserDetails[] | null = null;
    const track = this.track;
    if (track) {
      privateChatUsers = getPrivateChatUsers(track, this.currentUser.id, this.displayDetailsForUsers);
    }
    return privateChatUsers;
  }

  private get track(): Track | undefined {
    return this.activity?.track;
  }
}
