import { computed, ComputedRef, nextTick, Ref, ref, watch } from 'vue';
import { Route } from 'vue-router';

import { useRoute } from '@/composables/router/VueRouter';
import { TrackingCodes } from '@/data/datatypes/tracking/TrackingCodes';
import { useUserStore } from '@/stores/User';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const uncheckedWindow = window as any;
uncheckedWindow.dataLayer = uncheckedWindow.dataLayer || [];

// eslint-disable-next-line
function gtag(...args: any) {
  // It appears that the gtag lib relies on the `config` event being an `arguments` object. Attempting to type this
  // breaks google analytics.
  // eslint-disable-next-line prefer-rest-params
  uncheckedWindow.dataLayer.push((arguments?.length === 1) ? arguments[0] : arguments);
}

const userStore = useUserStore();

export function useGoogleAnalytics() {
  const route = useRoute();

  const trackRoute: Ref<boolean> = ref(true);

  const trackingCodes: ComputedRef<TrackingCodes> = computed(() => {
    return userStore.trackingCodes;
  });

  function postRouteChange(url: string, title: string): void {
    gtag('event', 'route_change', {
      pageUrl: url,
      pageTitle: title,
    });
  }

  function processFirstLogin(): void {
    if (userStore.isFirstLogin) {
      // The fake route is a legacy approach. Posting an event is a better approach.
      postRouteChange('/signupSuccess', document.title);
      gtag('event', 'signup_success');
      userStore.setFirstLoginProcessed();
    }
  }

  watch(() => trackingCodes.value, (newValue: TrackingCodes, oldValue?: TrackingCodes) => {
    // Don't try to handle changes in the code while we're running
    if (!newValue?.googleAnalytics || oldValue?.googleAnalytics) {
      return;
    }

    const docHead = document.head || document.getElementsByTagName('head')[0];
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${newValue.googleAnalytics}`;
    docHead.appendChild(scriptTag);

    gtag('js', new Date());
    gtag('config', newValue.googleAnalytics);
    processFirstLogin();
  }, { immediate: true });

  watch(() => route, (newValue: Route, oldValue?: Route) => {
    // Don't do this in mounted as having that in the mixin can interfere with the concrete classes' mounted function
    setTimeout(() => {
      if (!trackingCodes.value.googleAnalytics) {
        trackRoute.value = false;
      }
    }, 15000);
    if (!trackRoute.value || newValue?.fullPath === oldValue?.fullPath) {
      return;
    }
    const path = newValue.fullPath;
    // next tick to get the updated doc title
    nextTick(() => {
      postRouteChange(path, document.title);
    });
  }, { immediate: true });
}
